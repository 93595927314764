<div class="bg-light">
  <div class="container py-4 py-md-6">
    <p class="h1 mb-4 mb-md-5">Política de privacidad</p>

    <p>En cumplimiento de Ley No. 29733, Ley de Protección de Datos Personales, y su reglamento aprobado por Decreto Supremo No. 003-2013-JUS, deseamos informarte lo siguiente sobre el tratamiento de tus datos personales:</p>

    <div class="mb-4 mb-md-5">
      <p class="h4">1. IDENTIDAD Y DOMICILIO DEL TITULAR DEL BANCO DE DATOS PERSONALES</p>
      <p>El titular del banco de datos en el que se almacenarán los datos personales que nos proporciones es Karina Magaly Vega de Souza, con DNI No. 09850362, y con domicilio para estos efectos en Calle Ramón Ribeyro 890, dpto. 603, distrito de Barranco, Lima. Dicho banco se denomina “Banco de Datos de Clientes” y se encuentra inscrito en el Registro Nacional de Protección de Datos Personales.</p>
    </div>

    <div class="mb-4 mb-md-5">
      <p class="h4">2. FINALIDAD</p>
      <p>La empresa tratará los datos personales con la finalidad de prestar los Servicios, según este término es definido en la hoja de términos y condiciones de uso de la empresa, atender las consultas y/o solicitudes de los clientes al respecto, y cumplir las obligaciones asociadas a la prestación de los Servicios. Todos los términos aquí contenidos que empiecen en mayúscula y no tengan una definición en este documento, tendrán el significado que se les atribuye en los Términos y Condiciones de Uso.</p>
    </div>

    <div class="mb-4 mb-md-5">
      <p class="h4">3. DATOS PERSONALES SOLICITADOS</p>
      <p>Los datos que solicitamos para cumplir la anterior finalidad son los siguientes: nombre completo, documento de identidad (DNI, RUC o CE), número de celular, correo electrónico,  de ser el caso. Una vez registrados tus datos automáticamente declaras y certificas que ellos corresponden a ti y que son verdaderos, exactos, auténticos, completos, y correctos; y que eres mayor de edad.</p>
    </div>

    <div class="mb-4 mb-md-5">
      <p class="h4">4. CONSECUENCIAS DE NO PROPORCIONARNOS TUS DATOS PERSONALES</p>
      <p>De no proporcionarnos los datos personales indicados en el numeral 3, no será posible cumplir la finalidad indicada en el numeral 2. En tal sentido, para cumplir dicha finalidad los referidos datos son obligatorios.</p>
    </div>

    <div class="mb-4 mb-md-5">
      <p class="h4">5. TRANSFERENCIAS Y DESTINATARIOS DE LOS DATOS PERSONALES</p>
      <p>Usted se encuentra debidamente informado de que los Datos Personales podrán ser objeto de transferencia nacional a una empresa que brinde servicio de almacenamiento. Asimismo, podrá transferir sus datos a la persona o empresa que opere la plataforma tecnológica, de tiempo en tiempo.</p>
    </div>

    <div class="mb-4 mb-md-5">
      <p class="h4">6. PLAZO DE CONSERVACIÓN DE LOS DATOS</p>
      <p>Conservaremos los datos personales mientras se encuentre registrado, salvo norma legal que disponga algo distinto.</p>
    </div>

    <div class="mb-4 mb-md-5">
      <p class="h4">7.	EJERCICIO DE LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN</p>
      <p>Con los derechos ARCO podrás generar solicitudes respecto a:</p>
      <ul>
        <li>Derecho de acceso: Te permite averiguar en el banco de datos de Claro si tus datos personales están siendo usados y además, solicitar la información sobre el origen de dichos datos y además, las comunicaciones realizada con su uso.</li>
        <li>Derecho de rectificación: Te permite actualizar o completar tus datos personales faltantes. Sólo debes precisar qué datos deseas modificar o agregar en la solicitud y adjuntar un documento que lo valide.</li>
        <li>Derecho de cancelación: Té da la facultad de solicitar la eliminación  de tus datos personales siempre y cuando: (i) Hayan dejado de ser necesarios sobre el origen de la recopilación o (ii)Haya vencido el plazo establecido con el contrato.</li>
        <li>Derecho de oposición: Si en caso estén usando tus datos personales para otros motivos, podrás generar una solicitud para restringir  su uso.</li>
      </ul>
      <p>Puede ejercer sus derechos de acceso, rectificación, cancelación, oposición, a través de info@seguros360.pe.</p>
      <p>De considerar que no ha sido atendido en el ejercicio de sus derechos puede presentar una reclamación ante la Autoridad Nacional de Protección de Datos Personales del Ministerio de Justicia y Derechos Humanos, ubicado en calle Scipión Llona 350, Miraflores, llenando el formulario publicado en la página.</p>
    </div>

  </div>
</div>