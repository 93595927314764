import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-resultados-salud',
  templateUrl: './resultados-salud.component.html',
  styleUrls: ['./resultados-salud.component.scss']
})
export class ResultadosSaludComponent implements  OnChanges {

  @Input() valores: any;

  constructor() { }

  

  ngOnChanges(changes: SimpleChanges) {
    console.log(this.valores);
  }

}
