<div class="container mb-6 mb-md-8 py-3 py-md-4">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb extraSmall mb-3 mb-md-4">
      <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
      <li class="breadcrumb-item"><a routerLink="/seguros">Seguros</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{seguro.nombre}}</li>
    </ol>
  </nav>
  <div class="row gx-0 gy-4 gx-md-6">
    <div class="col-md-6">
      <div class="imageSeguroDetalle rounded-3" [style.background-image]="'url('+ seguro.image +')'">
      </div>
    </div>
    <div class="col-md-6">
      <p class="h2 fw-bold mb-3">{{seguro.nombre}}</p>
      <p class="lead mb-4">{{seguro.descripcion}}</p>
      <div class="mb-4">
        <div class="d-flex" *ngFor="let carcateristica of seguro.caracteristicas">
          <div class="me-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#00b341" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M5 12l5 5l10 -10" />
            </svg>
          </div>
          <p class="text-muted">{{carcateristica}}</p>
        </div>
      </div>
      <a class="btn btn-outline-secondary myBtn" href="https://api.whatsapp.com/send?phone=51950831391&text=Hola, te escribo desde la web de Seguros 360, me interesa el {{seguro.nombre}}" target="blank">
        <span class="me-1">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-whatsapp" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ff4500" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
            <path d="M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1" />
          </svg>
        </span>
        
        Me interesa</a>
    </div>
  </div>
</div>