<div class="backVida">
  <div class="container">
    <div class="row">

      <div class="col-md-6 bg-light">
        <div class="p-4 p-md-7 ps-md-0">
          <p class="display-4 fw-normal text-primary">¿Por qué un Seguro de Vida?</p>
          <div class="h5 fw-normal mb-4 mb-md-5">
            <p class="text-secondary">El Seguro de vida es un acto de amor hacia tu familia</p>
            <p>Es un seguro que busca acompañarte a lograr metas a lo largo de tu vida, mientras te proteges a ti y a
              tus seres queridos.</p>
            <p>Además, sirve como protección de brecha financiera ante un incidente no previsto que podría generar
              alguna crisis de bienestar.</p>
          </div>
          <a class="btn btn-outline-secondary"
            href="https://api.whatsapp.com/send?phone=51950831391&text=Hola, te escribo desde la web de Seguros 360, tengo una duda con el Seguro de Vida"
            target="blank">
            <span class="me-1">
              <i-tabler name="brand-whatsApp"></i-tabler>
            </span>
            <span>Conversemos</span>
          </a>
        </div>

      </div>

      <div class="col-md-6 bg-primary">
        <div class="p-4 py-5 p-md-7 pe-md-0">
          <div class="bg-white p-4 p-md-5 rounded-3">
            <p class="h5">¿Te llamamos?</p>
            <form [formGroup]="formVida" (ngSubmit)="onSubmit()" novalidate class="row gx-2 gx-md-3">

              <mat-form-field appearance="outline" class="col-12 col-md-6">
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="nombre">
                <mat-error *ngIf="errorNombre()">{{errorNombre()}}</mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" class="col-12 col-md-6">
                <mat-label>Celular</mat-label>
                <input matInput formControlName="celular">
                <mat-error *ngIf="errorCelular()">{{errorCelular()}}</mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" class="col-12">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email">
                <mat-error *ngIf="errorEmail()">{{errorEmail()}}</mat-error>
              </mat-form-field>



              <mat-form-field appearance="outline" class="col-12">
                <mat-label>Te llamamos</mat-label>
                <mat-select formControlName="horaContacto">
                  <mat-option *ngFor="let item of rangosHora" [value]="item">{{item}}</mat-option>
                </mat-select>
                <mat-error *ngIf="errorHoraContacto()">{{errorHoraContacto()}}</mat-error>
              </mat-form-field>

              <div class="mt-2">
                <button class="btn btn-secondary" [disabled]="!formVida.valid">
                  <span class="d-flex justify-content-center" *ngIf="loading">
                    <mat-spinner class="text-center" [diameter]="24"></mat-spinner>
                  </span>
                  <span *ngIf="!loading">Enviar datos</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>

  </div>

</div>





<!-- <div class="row g-0">

  <div class="col-md-6">
    <img
      src="https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/familiaLatinaSmall.jpg?alt=media&token=0ca10f3b-eb95-4543-b646-2b8d9391c333"
      style="object-fit: cover; width: 100%; height: 100%">
  </div>

  <div class="col-md-6 bg-secondary d-flex align-items-center py-4 px-4 px-md-7">
    <div>
      <p class="h3 text-white mb-3 mb-md-4">¿Necesito un seguro de vida?</p>
      <div class="bg-white rounded mb-3 d-flex p-3 shadow-sm" *ngFor="let motivo of motivos">
        <span class="me-2">
          <i-tabler name="check" class="text-success"
            style="width: 42px; stroke-width: 5; stroke-linecap: butt; stroke-linejoin: miter;"></i-tabler>
        </span>
        <p class="text-primary mb-0 small">{{motivo}}</p>
      </div>
    </div>

  </div>

</div> -->


<div class="container py-4 py-md-6">
  <p class="h1 text-center mb-4 mb-md-6 text-primary">¿Necesito un seguro de vida?</p>
  <div class="row gx-md-6 gy-6">
    <div class="col-md-4 text-center" *ngFor="let motivo of motivos">
      <img class="rounded-circle imageBeneficioVida mb-3 mb-md-4" [src]="motivo.image" alt="">
      <p class="h5 fw-light px-3 px-md-4">{{motivo.description}}</p>
    </div>
  </div>
</div>







<div class="container py-4 py-md-6">
  <p class="h2 text-primary mb-4">Tipos de Seguros de vida</p>
  <div class="row g-3 g-md-4">
    <div class="col-md-3" *ngFor="let seguro of tiposSeguros">
      <div
        class="border p-3 px-md-4 border rounded border-secondary border-2 border-primary h-100 d-flex align-items-center">
        <p class="h5 mb-0 fw-bolder lh-sm">{{seguro}}</p>
      </div>
    </div>
  </div>
</div>




<div class="container py-3 py-md-4">
  <p class="h2 text-primary mb-4">¿Qué cubre un Seguro de Vida?</p>
  <div class="bg-light p-3 p-md-5 rounded">
    <div class="row gy-2 gx-md-6">
      <div class="col-md-6 d-flex" *ngFor="let beneficio of beneficios">
        <span class="me-2">
          <i-tabler name="check" class="text-success"
            style="width: 24px; stroke-width: 2; stroke-linecap: butt; stroke-linejoin: miter;"></i-tabler>
        </span>
        <p class="lead text-primary mb-0 small">{{beneficio}}</p>
      </div>
    </div>
  </div>
</div>


<div class="container pt-2 pt-md-3 pb-4 pb-md-6">
  <p class="h2 text-primary mb-4">Beneficios Adicionales</p>

  <div class="row g-3 g-md-4 justify-content-center">
    <div class="col-md-4" *ngFor="let adicional of adicionales">
      <div class="bg-light p-3 p-md-5 rounded text-center">
        <p class="h5 mb-0">{{adicional}}</p>
      </div>
    </div>
  </div>


</div>





<div class="container">
  <div class="px-4 pb-5 pb-md-7 text-center">
    <p class="lead">¿Dudas con el Seguro de Vida?</p>
    <a class="btn btn-outline-secondary"
      href="https://api.whatsapp.com/send?phone=51950831391&text=Hola, te escribo desde la web de Seguros 360, tengo una duda con el Seguro de Vida"
      target="blank">
      <span class="me-1">
        <i-tabler name="brand-whatsApp"></i-tabler>
      </span>
      <span>Conversemos</span>
    </a>
  </div>
</div>