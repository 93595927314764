import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit, AfterViewInit {

  categorias = [];
  cotizadores = [];
  aseguradoras = [];

  private fragment: string;


  testimonios = [
    {
      nombre: 'Luciano Uceda',
      cargo: 'CEO - Sudamericana de Cargas S.R.L.',
      comentario: 'El servicio brindado por Eagles Corredores de Seguros es muy eficiente y realmente oportuno, he conseguido las pólizas para mis camiones en el momento en el que las necesitaba.'
    },
    {
      nombre: 'Erik Cavalie Fiedler',
      cargo: 'Gerente General - Rinol Pavimenta S.A.C.',
      comentario: 'Nuestra experiencia con Eagles Corredores de Seguros ha sido de una manera muy profesional y personalizada, contando siempre con la mejor asesoría gracias a su nivel de conocimiento en seguros, buscando las mejores opciones y los mejores precios para nuestra empresa.'
    },
    {
      nombre: 'Luciano Mazetti',
      cargo: 'Conductor TV - Plus TV',
      comentario: 'En Eagles Corredores de Seguros encontré un equipo comprometido con los clientes, enfocado en brindar una excelente atención.'
    },
    {
      nombre: 'Maria Luz Ramos',
      cargo: 'Jefe de Gestión Humana – KIO Estaciones de Servicio',
      comentario: 'La asesoría adecuada para decidir por la mejor cobertura para mis colaboradores, la encontré en Eagles Corredores de Seguros.'
    },
   
  ];

  

  constructor(
    private ds: DataService,
    private titleService: Title,
    private metaService: Meta,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.categorias = this.ds.categorias;
    this.cotizadores = this.ds.cotizadores;
    this.aseguradoras = this.ds.aseguradoras;

    this.metaService.addTags([
      { name: 'keywords', content: 'Keyword1, Keyword2' },
      { name: 'description', content: 'Your SEO desc' },
    ]);

    // this.titleService('Your seo title');


    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => { //I needed also setTimeout in order to make it work
      try {
        document.querySelector('#' + this.fragment).scrollIntoView();
      } catch (e) {
      }
    });
  }

}
