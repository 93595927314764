<div class="container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb extraSmall mb-3 mb-md-4">
      <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
      <li class="breadcrumb-item active">Nosotros</li>
    </ol>
  </nav>
</div>
<div class="bannerNosotros"></div>

<div class="container">
  <div class="row py-4 py-md-6">
    <div class="col-md-5">
      <p class="h2 text-primary">Somos un corredor de seguros enfocado en que vivas #SinPreocupaciones</p>
    </div>
    <div class="col-md-6">
      <p class="lead">Si tienes una empresa o quieres proteger tu salud y tus bienes, ¡Nosotros te ayudamos!</p>
      <p class="small text-muted">Te brindamos asesoría personalizada en protección de tu salud, bienestar y seguridad con productos que cuentan con el respaldo de las mejores aseguradoras del país. Además, brindamos otros servicios relacionados, como evaluación de riesgos, auditoría de pólizas, evaluación de siniestros y otros.</p>
    </div>
  </div>
</div>

<app-socios></app-socios>