<div class="backCotizador">

  <div class="container">

    <div class="row">

      <div class="col-md-6 align-self-center bg-primary">
        <div class="text-white p-4 p-md-0">
          <p class="h1 mb-4">Cotiza tu SOAT para autos y camionetas al instante</p>
          <!-- <p class="lead">Compara los precios de cada aseguradora</p> -->
          <div class="d-flex mb-2" *ngFor="let item of points">
            <span class="me-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#06AB3E" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="12" cy="12" r="9" />
                <path d="M9 12l2 2l4 -4" />
              </svg>
            </span>
            <div class="pt-1">
              <span class="fw-light">{{item}}</span>
            </div>
            
          </div>
        </div>
      </div>

      <div class="col-md-6  bg-light">
        <div class="p-4 ps-md-7 py-md-7 pe-md-0">
          <p class="h3">Datos del Vehículo</p>
          <form [formGroup]="formSoatAuto" (ngSubmit)="onSubmit()" novalidate class="row gx-0" *ngIf="marcas">
            <mat-form-field appearance="standard" class="col-12">
              <mat-label>Marca</mat-label>
              <mat-select formControlName="marca">
                <mat-option *ngFor="let marca of marcas" [value]="marca">
                  {{marca}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="errorMarca()">{{errorMarca()}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard" class="col-12">
              <mat-label>Modelo</mat-label>
              <mat-select formControlName="modelo">
                <mat-option *ngFor="let modelo of modelosFilter" [value]="modelo">
                  {{modelo}}</mat-option>
              </mat-select>
              <mat-error *ngIf="errorModelo()">{{errorModelo()}}</mat-error>
            </mat-form-field>
            <div class="d-grid mt-2" *ngIf="!visible">
              <button class="btn btn-secondary myBtn" [disabled]="!formSoatAuto.valid">
                <span class="d-flex justify-content-center" *ngIf="loading">
                  <mat-spinner class="text-center" [diameter]="24"></mat-spinner>
                </span>
                <span *ngIf="!loading">ver precios</span>
              </button>
            </div>
          </form>
        </div>
      </div>

    </div>

  </div>

</div>




<div class="container">

  <div class="px-2 mt-3 mt-md-4" *ngIf="visible">
    <p class="small">Mostrando precios para SOAT Auto: <span class="fw-bold">{{formSoatAuto.get('marca').value}} -
        {{formSoatAuto.get('modelo').value}}</span></p>
    <mat-accordion>
      <app-card-soat-auto *ngFor="let item of primasSoatAutosFilter; let primero = first; let ultimo = last"
        [ngClass]="{ 'primerItemAcordion': primero, 'ultimoItemAcordion': ultimo }" [item]="item"
        [formSoatAuto]="formSoatAuto"></app-card-soat-auto>
    </mat-accordion>
  </div>

  <div class="p-4 p-md-7 text-center">
    <p class="lead mb-0">¿Dudas con el SOAT para Autos?</p>
    <a href="https://api.whatsapp.com/send?phone=51950831391&text=Hola, te escribo desde la web de Seguros 360, tengo una duda con el SOAT para Autos"
      target="blank">Cuéntanos</a>
  </div>

</div>