import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { PrimaSalud } from 'src/app/classes/prima-salud';
import { FirebaseService } from 'src/app/services/firebase.service';
import { DataService } from 'src/app/services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/app';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-form-cotizador-salud',
  templateUrl: './form-cotizador-salud.component.html',
  styleUrls: ['./form-cotizador-salud.component.scss']
})
export class FormCotizadorSaludComponent implements OnInit {

  formSalud: UntypedFormGroup;
  primasSalud: PrimaSalud[];
  primasSaludAdulto: PrimaSalud[];
  primasSaludConyuge: PrimaSalud[];
  primasSaludHijo: PrimaSalud[];
  primasFilter;
  primasFilterEdad = [];
  edades = [];
  loader = true;
  vinculos = [ 'Cónyuge', 'Hijo(a)' ];
  subTotalFamiliares = 0;
  edadesFamiliares = [];
  myFormValueChanges$;
  totalSumSaludP = 0;
  saludPreferencial = 0;
  primasResult = [];
  primasResultSort;

  primasBasico: Observable<any[]>;
  primasAvanzado: Observable<any[]>;
  primasPremium: Observable<any[]>;
  primasInternacional: Observable<any[]>;

  saludPreferencialArr = [];
  fullSaludArr = [];
  redMedicaArr = [];
  redPreferenteRimArr = [];
  medicVidaInternacionalArr = [];
  medicvidaNacionalArr = [];
  multiSaludArr = [];
  redPreferentePacArr = [];
  vivaSaludArr = [];
  vivaSaludPlusArr = [];
  trebolSaludArr = [];
  previsorArr = [];
  medisaludGlobalArr = [];
  medisaludPremiumArr = [];
  medisaludPlusArr = [];
  medisaludArr = [];

  saludPreferencialSumFam = 0;
  fullSaludSumFam = 0;
  redMedicaSumFam = 0;
  redPreferenteRimSumFam = 0;
  medicVidaInternacionalSumFam = 0;
  medicvidaNacionalSumFam = 0;
  multiSaludSumFam = 0;
  redPreferentePacSumFam = 0;
  vivaSaludSumFam = 0;
  vivaSaludPlusSumFam = 0;
  trebolSaludSumFam = 0;
  previsorSumFam = 0;
  medisaludGlobalSumFam = 0;
  medisaludPremiumSumFam = 0;
  medisaludPlusSumFam = 0;
  medisaludSumFam = 0;

  saludPreferencialFam = [];
  fullSaludFam = [];
  redMedicaFam = [];
  redPreferenteRimFam = [];
  medicVidaInternacionalFam = [];
  medicvidaNacionalFam = [];
  multiSaludFam = [];
  redPreferentePacFam = [];
  vivaSaludFam = [];
  vivaSaludPlusFam = [];
  trebolSaludFam = [];
  previsorFam = [];
  medisaludGlobalFam = [];
  medisaludPremiumFam = [];
  medisaludPlusFam = [];
  medisaludFam = [];

  aseguradoras: any;

  tceaRimac = 0.0000000001;
  tceaPacifico = 0.04;
  tceaMapfre = 0.179;
  tceaLaPositiva = 0.0000000001;

  coberturas;
  exclusiones;

  clinicasSaludPreferencial;
  clinicasFullSalud;
  clinicasRedMedica;
  clinicasRedPreferenteRim;
  clinicasMedicvidaInternacional;
  clinicasMedicvidaNacional;
  clinicasMultisalud;
  clinicasRedPreferentePac;
  clinicasVivaSalud;
  clinicasVivaSaludPlus;
  clinicasTrebolSalud;
  clinicasMedisaludGlobal;
  clinicasMedisaludPremium;
  clinicasMedisaludPlus;
  clinicasMedisalud;

  planesSalud = [];

  beneficiosPlanBasico = [
    'Red de clínicas estándar'
  ];

  beneficiosPlanAvanzado = [
    'Amplia red de clínicas nacionales'
  ];

  beneficiosPlanPremium = [
    'Reembolso por atención en otras clínicas',
    'Amplia red de clínicas nacionales'
  ];

  beneficiosPlanInternacional = [
    'Reembolso por atención en otras clínicas',
    'Amplia red de clínicas nacionales',
    'Cobertura en clínicas internacionales'
  ];

  edadTitular: number;
  visible = false;
  loading = false;

  points = [
    'Obtén el precio al instante',
    'Compara entre las principales compañías aseguradoras del Perú',
    'Agrega familiares',
    'Obtén asesoría personalizada sin nungún compromiso'
  ];



  motivos = [
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/seguroVida%2F1Baja.jpg?alt=media&token=f9c9e1b5-757e-4ce3-a8ee-339a2ada1809',
      description: 'Si tienes hijos menores de 18 años, planifica su futuro y estudios escolares y universitarios.'
    },
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/seguroVida%2F2Baja.jpg?alt=media&token=d7cf48e0-38be-4ee8-a4f9-37f953e83030',
      description: 'Si tienes un crédito hipotecario, puedes reemplazar el seguro de desgravamen.'
    },
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/seguroVida%2F3Baja.jpg?alt=media&token=786efa13-fded-42a6-927c-44ad6afd6551',
      description: 'Si eres joven y aún no tienes hijos, ahorra para cumplir tus metas de vida: un auto nuevo, tu depa.'
    },
  ];

  tiposSeguros = [
    'Seguro Integral de Salud',
    'Seguros contra accidentes Personales',
    'Seguro de salud EPS',
    'Seguros oncológicos',
    'Seguro de asistencia médica individual nacional e internacional',
  ];

  beneficios = [
    'Atención de Emergencia',
    'Cobertura oftalmológica',
    'Hospitalización',
    'Maternidad: parto o cesárea',
    'Atención ambulatoria',
    'Medicos a domicilio y por teléfono',
    'Cobertura oncológica',
    'Tratamientos complejos',
    'Cobertura odontológica'
  ];

  adicionales = [
    'Programa de enfermedades Crónicas',
    'Psicología y Psiquiatría',
    'Quiropraxia',
    'Farmacia',
    'Ambulancia',
    'Chequeo preventivo',
    'Consultas virtuales cubiertas al 100%',
    'Sepelio',
    'Terapia Física y Rehabilitación',
    'Vacunas',
    'Receta extendida'
  ]

  constructor(
    private fs: FirebaseService,
    private fb: UntypedFormBuilder,
    private ds: DataService,
    private dialog: MatDialog,
    private afs: AngularFirestore,
  ) { }

  ngOnInit(): void {

    this.formSalud = this.fb.group({
      nombre: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      celular: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(9), Validators.maxLength(9)]],
      edadTitular: ['', Validators.required],
      familiares: this.fb.array([]),
    });

    this.fs.getPrimasSaludAdulto().subscribe( (res: any) => {
      this.primasSaludAdulto = res;
      this.loader = false;
      const edadesOrigin = this.primasSaludAdulto.map(a => a.edad);
      // this.edades = edadesOrigin.slice(-101, 65);
      this.edades = edadesOrigin;
    });

    this.fs.getPrimasSaludConyuge().subscribe( (res: any) => {
      this.primasSaludConyuge = res;
    });

    this.fs.getPrimasSaludHijo().subscribe( (res: any) => {
      this.primasSaludHijo = res;
    });

    // this.ds.getCoberturasSalud().subscribe((res: any) => {
    //   this.coberturas = res;
    // });

    // this.ds.getExclusionesSalud().subscribe((res: any) => {
    //   this.exclusiones = res;
    // });

    this.fs.getPlanesSalud().subscribe( res => {
      this.planesSalud = res;
    });


    // this.ds.getClinicasRedPreferenteRim().subscribe( (res: any) => { this.clinicasRedPreferenteRim = res; });
    // this.ds.getClinicasVivaSalud().subscribe( (res: any) => { this.clinicasVivaSalud = res; });
    // this.ds.getClinicasRedPreferentePac().subscribe( (res: any) => { this.clinicasRedPreferentePac = res; });
    // this.ds.getClinicasMedisalud().subscribe( (res: any) => { this.clinicasMedisalud = res; });

    // this.ds.getClinicasRedMedica().subscribe( (res: any) => { this.clinicasRedMedica = res; });
    // this.ds.getClinicasVivaSaludPlus().subscribe( (res: any) => { this.clinicasVivaSaludPlus = res; });
    // this.ds.getClinicasMultisalud().subscribe( (res: any) => { this.clinicasMultisalud = res; });
    // this.ds.getClinicasMedisaludPlus().subscribe( (res: any) => { this.clinicasMedisaludPlus = res; });

    // this.ds.getClinicasFullSalud().subscribe( (res: any) => { this.clinicasFullSalud = res; });
    // this.ds.getClinicasTrebolSalud().subscribe( (res: any) => { this.clinicasTrebolSalud = res; });
    // this.ds.getClinicasMedicvidaNacional().subscribe( (res: any) => { this.clinicasMedicvidaNacional = res; });
    // this.ds.getClinicasMedisaludPremium().subscribe( (res: any) => { this.clinicasMedisaludPremium = res; });

    // this.ds.getClinicasSaludPreferencial().subscribe( (res: any) => { this.clinicasSaludPreferencial = res; });
    // this.ds.getClinicasMedicvidaInternacional().subscribe( (res: any) => { this.clinicasMedicvidaInternacional = res; });
    // this.ds.getClinicasMedisaludGlobal().subscribe( (res: any) => { this.clinicasMedisaludGlobal = res; });


    this.formSalud.get('edadTitular').valueChanges.subscribe( res => {

      this.edadTitular = res;
      this.primasFilter = this.primasSaludAdulto.find( find => find.edad === res );

      this.saludPreferencialArr.splice(0, 1, this.primasFilter.primas[0].prima);
      this.fullSaludArr.splice(0, 1, this.primasFilter.primas[1].prima);
      this.redMedicaArr.splice(0, 1, this.primasFilter.primas[2].prima);
      this.redPreferenteRimArr.splice(0, 1, this.primasFilter.primas[3].prima);
      this.medicVidaInternacionalArr.splice(0, 1, this.primasFilter.primas[4].prima);
      this.medicvidaNacionalArr.splice(0, 1, this.primasFilter.primas[5].prima);
      this.multiSaludArr.splice(0, 1, this.primasFilter.primas[6].prima);
      this.redPreferentePacArr.splice(0, 1, this.primasFilter.primas[7].prima);
      this.vivaSaludArr.splice(0, 1, this.primasFilter.primas[8].prima);
      this.vivaSaludPlusArr.splice(0, 1, this.primasFilter.primas[9].prima);
      this.trebolSaludArr.splice(0, 1, this.primasFilter.primas[10].prima);
      this.previsorArr.splice(0, 1, this.primasFilter.primas[11].prima);
      this.medisaludGlobalArr.splice(0, 1, this.primasFilter.primas[12].prima);
      this.medisaludPremiumArr.splice(0, 1, this.primasFilter.primas[13].prima);
      this.medisaludPlusArr.splice(0, 1, this.primasFilter.primas[14].prima);
      this.medisaludArr.splice(0, 1, this.primasFilter.primas[15].prima);

      // this.addItemArray();
      this.getSumasFamiliares();
      this.getArrayFinal();
      this.primasResultOrder();
      this.getPrimasAllFilter();
      // this.onSubmit();
    });

    this.myFormValueChanges$ = this.formSalud.controls.familiares.valueChanges;
    this.myFormValueChanges$.subscribe(familiares => this.updateTotalUnitPrice(familiares));

    this.aseguradoras = this.ds.aseguradoras;
    // this.aseguradoras = this.ds.aseguradoras.filter( fil =>  fil.nombre === 'Mapfre' || fil.nombre === 'La Positiva' || fil.nombre === 'Qualitas' );
    // console.log(this.aseguradoras);
  }


  onSubmit() {
    this.crearcontacto();
    this.loading = true;
    setTimeout(() => {
      this.visible = true;
    }, 300);
  }

  crearcontacto() {
    this.afs.collection('/cotizacionesSalud').add({
      dataContacto: {
        nombre: this.formSalud.value.nombre,
        email: this.formSalud.value.email,
        celular: this.formSalud.value.celular,
        fechaCreacion: Timestamp.now()
      },
      dataSeguro: {
        edadTitular: this.formSalud.value.edadTitular,
        familiares: this.formSalud.value.familiares
      },
    });
  }

  getClinicas(plan) {
    const planSalud = this.planesSalud.find( find => find.id === plan);
    return planSalud.clinicas;
  }

  getCoberturas(plan) {
    const planSalud = this.planesSalud.find( find => find.id === plan);
    return planSalud.coberturas;
  }

  getExclusiones(plan) {
    const planSalud = this.planesSalud.find( find => find.id === plan);
    return planSalud.exclusiones;
  }

  getFamiliar() {
    return this.fb.group({
      vinculo: ['', Validators.required],
      edad: ['', Validators.required],
      costo: ['']
    });
  }


  agregarFamiliar() {
    const control = this.formSalud.controls.familiares as UntypedFormArray;
    control.push(this.getFamiliar());
  }

  eliminarFamiliar(i: number) {
    const myArray = this.formSalud.controls.familiares as UntypedFormArray;
    this.removeItemArray(i);
    this.removeItemArrayFam(i);
    this.getSumasFamiliares();
    this.getArrayFinal();
    this.primasResultOrder();
    this.getPrimasAllFilter();
    const control = this.formSalud.controls.familiares as UntypedFormArray;
    control.removeAt(i);
  }

  private updateTotalUnitPrice(familiares: any) {

    const myArray = this.formSalud.controls.familiares as UntypedFormArray;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < myArray.length; i++) {

      myArray.at(+i).get('edad').valueChanges.subscribe( res => {

        if (myArray.at(+i).get('vinculo').value === 'Cónyuge') {
          this.primasFilter = this.primasSaludConyuge.find( find => find.edad === res );
        }

        if (myArray.at(+i).get('vinculo').value === 'Hijo(a)') {
          this.primasFilter = this.primasSaludHijo.find( find => find.edad === res );
        }
        // Obtener array solo de precios por cada familiar
        this.saludPreferencialArr.splice((i + 1), 1, this.primasFilter.primas[0].prima);
        this.saludPreferencialArr.splice((i + 1), 1, this.primasFilter.primas[0].prima);
        this.fullSaludArr.splice((i + 1), 1, this.primasFilter.primas[1].prima);
        this.redMedicaArr.splice((i + 1), 1, this.primasFilter.primas[2].prima);
        this.redPreferenteRimArr.splice((i + 1), 1, this.primasFilter.primas[3].prima);
        this.medicVidaInternacionalArr.splice((i + 1), 1, this.primasFilter.primas[4].prima);
        this.medicvidaNacionalArr.splice((i + 1), 1, this.primasFilter.primas[5].prima);
        this.multiSaludArr.splice((i + 1), 1, this.primasFilter.primas[6].prima);
        this.redPreferentePacArr.splice((i + 1), 1, this.primasFilter.primas[7].prima);
        this.vivaSaludArr.splice((i + 1), 1, this.primasFilter.primas[8].prima);
        this.vivaSaludPlusArr.splice((i + 1), 1, this.primasFilter.primas[9].prima);
        this.trebolSaludArr.splice((i + 1), 1, this.primasFilter.primas[10].prima);
        this.previsorArr.splice((i + 1), 1, this.primasFilter.primas[11].prima);
        this.medisaludGlobalArr.splice((i + 1), 1, this.primasFilter.primas[12].prima);
        this.medisaludPremiumArr.splice((i + 1), 1, this.primasFilter.primas[13].prima);
        this.medisaludPlusArr.splice((i + 1), 1, this.primasFilter.primas[14].prima);
        this.medisaludArr.splice((i + 1), 1, this.primasFilter.primas[15].prima);

        // desglose de precios por cada familiar
        // this.saludPreferencialFam.splice( i, 1, this.getItemsArray(myArray, i, this.saludPreferencialArr[i + 1], this.tceaRimac));
        // this.fullSaludFam.splice( i, 1, this.getItemsArray(myArray, i, this.fullSaludArr[i + 1], this.tceaRimac));
        // this.redMedicaFam.splice( i, 1, this.getItemsArray(myArray, i, this.redMedicaArr[i + 1], this.tceaRimac));
        // this.redPreferenteRimFam.splice( i, 1, this.getItemsArray(myArray, i, this.redPreferenteRimArr[i + 1], this.tceaRimac));
        // this.medicVidaInternacionalFam.splice( i, 1, this.getItemsArray(myArray, i, this.medicVidaInternacionalArr[ i + 1], this.tceaPacifico));
        // this.medicvidaNacionalFam.splice( i, 1, this.getItemsArray(myArray, i, this.medicvidaNacionalArr[i + 1], this.tceaPacifico));
        // this.multiSaludFam.splice( i, 1, this.getItemsArray(myArray, i, this.multiSaludArr[i + 1], this.tceaPacifico));
        // this.redPreferentePacFam.splice( i, 1, this.getItemsArray(myArray, i, this.redPreferentePacArr[i + 1], this.tceaPacifico));
        // this.vivaSaludFam.splice( i, 1, this.getItemsArray(myArray, i, this.vivaSaludArr[i + 1], this.tceaMapfre));
        // this.vivaSaludPlusFam.splice( i, 1, this.getItemsArray(myArray, i, this.vivaSaludPlusArr[i + 1], this.tceaMapfre));
        // this.trebolSaludFam.splice( i, 1, this.getItemsArray(myArray, i, this.trebolSaludArr[i + 1], this.tceaMapfre));
        // this.previsorFam.splice( i, 1, this.getItemsArray(myArray, i, this.previsorArr[i + 1], this.tceaMapfre));
        // this.medisaludGlobalFam.splice( i, 1, this.getItemsArray(myArray, i, this.medisaludGlobalArr[i + 1], this.tceaLaPositiva));
        // this.medisaludPremiumFam.splice( i, 1, this.getItemsArray(myArray, i, this.medisaludPremiumArr[i + 1], this.tceaLaPositiva));
        // this.medisaludPlusFam.splice( i, 1, this.getItemsArray(myArray, i, this.medisaludPlusArr[i + 1], this.tceaLaPositiva));
        // this.medisaludFam.splice( i, 1, this.getItemsArray(myArray, i, this.medisaludArr[i + 1], this.tceaLaPositiva));

        this.getItemsFamiliares(myArray, i);
        this.getSumasFamiliares();
        this.getArrayFinal();
        this.primasResultOrder();
        this.getPrimasAllFilter();
        
      });

    }

  }

  getItemsArray(myArray, i, costoArr, tipoTcea) {
    return {
      vinculo: myArray.at(+i).get('vinculo').value,
      edad: myArray.at(+i).get('edad').value,
      costo: Math.round(this.getTcea(costoArr, tipoTcea))
    };
  }

  getSumasFamiliares() {
    this.saludPreferencialSumFam = this.saludPreferencialArr.reduce((partialsum, a) => partialsum + a, 0);
    this.fullSaludSumFam = this.fullSaludArr.reduce((partialsum, a) => partialsum + a, 0);
    this.redMedicaSumFam = this.redMedicaArr.reduce((partialsum, a) => partialsum + a, 0);
    this.redPreferenteRimSumFam = this.redPreferenteRimArr.reduce((partialsum, a) => partialsum + a, 0);
    this.medicVidaInternacionalSumFam = this.medicVidaInternacionalArr.reduce((partialsum, a) => partialsum + a, 0);
    this.medicvidaNacionalSumFam = this.medicvidaNacionalArr.reduce((partialsum, a) => partialsum + a, 0);
    this.multiSaludSumFam = this.multiSaludArr.reduce((partialsum, a) => partialsum + a, 0);
    this.redPreferentePacSumFam = this.redPreferentePacArr.reduce((partialsum, a) => partialsum + a, 0);
    this.vivaSaludSumFam = this.vivaSaludArr.reduce((partialsum, a) => partialsum + a, 0);
    this.vivaSaludSumFam = this.vivaSaludArr.reduce((partialsum, a) => partialsum + a, 0);
    this.vivaSaludPlusSumFam = this.vivaSaludPlusArr.reduce((partialsum, a) => partialsum + a, 0);
    this.trebolSaludSumFam = this.trebolSaludArr.reduce((partialsum, a) => partialsum + a, 0);
    this.previsorSumFam = this.previsorArr.reduce((partialsum, a) => partialsum + a, 0);
    this.medisaludGlobalSumFam = this.medisaludGlobalArr.reduce((partialsum, a) => partialsum + a, 0);
    this.medisaludPremiumSumFam = this.medisaludPremiumArr.reduce((partialsum, a) => partialsum + a, 0);
    this.medisaludPlusSumFam = this.medisaludPlusArr.reduce((partialsum, a) => partialsum + a, 0);
    this.medisaludSumFam = this.medisaludArr.reduce((partialsum, a) => partialsum + a, 0);
  }

  getItemsFamiliares(myArray, i) {
    this.saludPreferencialFam.splice( i, 1, this.getItemsArray(myArray, i, this.saludPreferencialArr[i + 1], this.tceaRimac));
    this.fullSaludFam.splice( i, 1, this.getItemsArray(myArray, i, this.fullSaludArr[i + 1], this.tceaRimac));
    this.redMedicaFam.splice( i, 1, this.getItemsArray(myArray, i, this.redMedicaArr[i + 1], this.tceaRimac));
    this.redPreferenteRimFam.splice( i, 1, this.getItemsArray(myArray, i, this.redPreferenteRimArr[i + 1], this.tceaRimac));
    this.medicVidaInternacionalFam.splice( i, 1, this.getItemsArray(myArray, i, this.medicVidaInternacionalArr[ i + 1], this.tceaPacifico));
    this.medicvidaNacionalFam.splice( i, 1, this.getItemsArray(myArray, i, this.medicvidaNacionalArr[i + 1], this.tceaPacifico));
    this.multiSaludFam.splice( i, 1, this.getItemsArray(myArray, i, this.multiSaludArr[i + 1], this.tceaPacifico));
    this.redPreferentePacFam.splice( i, 1, this.getItemsArray(myArray, i, this.redPreferentePacArr[i + 1], this.tceaPacifico));
    this.vivaSaludFam.splice( i, 1, this.getItemsArray(myArray, i, this.vivaSaludArr[i + 1], this.tceaMapfre));
    this.vivaSaludPlusFam.splice( i, 1, this.getItemsArray(myArray, i, this.vivaSaludPlusArr[i + 1], this.tceaMapfre));
    this.trebolSaludFam.splice( i, 1, this.getItemsArray(myArray, i, this.trebolSaludArr[i + 1], this.tceaMapfre));
    this.previsorFam.splice( i, 1, this.getItemsArray(myArray, i, this.previsorArr[i + 1], this.tceaMapfre));
    this.medisaludGlobalFam.splice( i, 1, this.getItemsArray(myArray, i, this.medisaludGlobalArr[i + 1], this.tceaLaPositiva));
    this.medisaludPremiumFam.splice( i, 1, this.getItemsArray(myArray, i, this.medisaludPremiumArr[i + 1], this.tceaLaPositiva));
    this.medisaludPlusFam.splice( i, 1, this.getItemsArray(myArray, i, this.medisaludPlusArr[i + 1], this.tceaLaPositiva));
    this.medisaludFam.splice( i, 1, this.getItemsArray(myArray, i, this.medisaludArr[i + 1], this.tceaLaPositiva));
  }

  removeItemArrayFam(i: number) {
    this.saludPreferencialFam.splice(i, 1);
    this.fullSaludFam.splice(i, 1);
    this.redMedicaFam.splice(i, 1);
    this.redPreferenteRimFam.splice(i, 1);
    this.medicVidaInternacionalFam.splice(i, 1);
    this.medicvidaNacionalFam.splice(i, 1);
    this.multiSaludFam.splice(i, 1);
    this.redPreferentePacFam.splice(i, 1);
    this.vivaSaludFam.splice(i, 1);
    this.vivaSaludPlusFam.splice(i, 1);
    this.trebolSaludFam.splice(i, 1);
    this.previsorFam.splice(i, 1);
    this.medisaludGlobalFam.splice(i, 1);
    this.medisaludPremiumFam.splice(i, 1);
    this.medisaludPlusFam.splice(i, 1);
    this.medisaludFam.splice(i, 1);
  }

  removeItemArray(i: number) {
    this.saludPreferencialArr.splice((i + 1), 1);
    this.fullSaludArr.splice((i + 1), 1);
    this.redMedicaArr.splice((i + 1), 1);
    this.redPreferenteRimArr.splice((i + 1), 1);
    this.medicVidaInternacionalArr.splice((i + 1), 1);
    this.medicvidaNacionalArr.splice((i + 1), 1);
    this.multiSaludArr.splice((i + 1), 1);
    this.redPreferentePacArr.splice((i + 1), 1);
    this.vivaSaludArr.splice((i + 1), 1);
    this.vivaSaludPlusArr.splice((i + 1), 1);
    this.trebolSaludArr.splice((i + 1), 1);
    this.previsorArr.splice((i + 1), 1);
    this.medisaludGlobalArr.splice((i + 1), 1);
    this.medisaludPremiumArr.splice((i + 1), 1);
    this.medisaludPlusArr.splice((i + 1), 1);
    this.medisaludArr.splice((i + 1), 1);
  }

  getTcea(costoAnual, tcea) {
    const a = Math.pow(1 + tcea, 1 / 12) - 1;
    const b = Math.pow(1 + a, 12);
    return costoAnual * (a * b) / (b - 1);
  }

  getArrayFinal() {
    return this.primasResult = [
      {
        aseguradora: this.aseguradoras[0].nombre,
        logo: this.aseguradoras[0].logo,
        plan: 'Salud Preferencial',
        prima: Math.round(this.saludPreferencialSumFam / 12),
        categoria: 'Internacional',
        edadTitular: this.edadTitular,
        costoTitular: Math.round(this.saludPreferencialArr[0] / 12),
        familiares: this.saludPreferencialFam,
        coberturas: this.getCoberturas('saludPreferencial'),
        exclusiones: this.getExclusiones('saludPreferencial'),
        clinicas: this.getClinicas('saludPreferencial'),
        cartilla: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/cartillasPdf%2Fsalud%2FsaludPreferencial.pdf?alt=media&token=e50d6110-07bf-4a47-b840-6bf88ea23eb7',
        condicionado: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/condicionadosPdf%2Fsalud%2FsaludPreferencial.pdf?alt=media&token=738d92f5-1af0-4b5f-ab84-3a24628ac82a'
      },
      {
        aseguradora: this.aseguradoras[0].nombre,
        logo: this.aseguradoras[0].logo,
        plan: 'Full Salud',
        prima: Math.round(this.fullSaludSumFam / 12),
        categoria: 'Premium',
        edadTitular: this.edadTitular,
        costoTitular: Math.round(this.fullSaludArr[0] / 12),
        familiares: this.fullSaludFam,
        coberturas: this.getCoberturas('fullSalud'),
        exclusiones: this.getExclusiones('fullSalud'),
        clinicas: this.getClinicas('fullSalud'),
        cartilla: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/cartillasPdf%2Fsalud%2FfullSalud.pdf?alt=media&token=208439a2-0999-4ad7-8afd-936fd92d1aa0',
        condicionado: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/condicionadosPdf%2Fsalud%2FfullSalud.pdf?alt=media&token=4824ae9b-c912-4397-b1e9-0f68e36e89ad'
      },
      {
        aseguradora: this.aseguradoras[0].nombre,
        logo: this.aseguradoras[0].logo,
        plan: 'Red Médica',
        prima: Math.round(this.redMedicaSumFam / 12),
        categoria: 'Avanzado',
        edadTitular: this.edadTitular,
        costoTitular: Math.round(this.redMedicaArr[0] / 12),
        familiares: this.redMedicaFam,
        coberturas: this.getCoberturas('redMedica'),
        exclusiones: this.getExclusiones('redMedica'),
        clinicas: this.getClinicas('redMedica'),
        cartilla: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/cartillasPdf%2Fsalud%2FredMedica.pdf?alt=media&token=3884ea70-48ca-4516-923d-f59b1efdcb87',
        condicionado: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/condicionadosPdf%2Fsalud%2FredMedica.pdf?alt=media&token=d1ed348b-54af-4541-beff-121a6642e856'
      },
      {
        aseguradora: this.aseguradoras[0].nombre,
        logo: this.aseguradoras[0].logo,
        plan: 'Red Preferente',
        prima: Math.round(this.redPreferenteRimSumFam / 12),
        categoria: 'Básico',
        edadTitular: this.edadTitular,
        costoTitular: Math.round(this.redPreferenteRimArr[0] / 12),
        familiares: this.redPreferenteRimFam,
        coberturas: this.getCoberturas('redPreferenteRim'),
        exclusiones: this.getExclusiones('redPreferenteRim'),
        clinicas: this.getClinicas('redPreferenteRim'),
        cartilla: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/cartillasPdf%2Fsalud%2FredPreferenteRim.pdf?alt=media&token=9b373e61-9744-479d-8757-807c658f8fe0',
        condicionado: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/condicionadosPdf%2Fsalud%2FredPreferenteRim.pdf?alt=media&token=8e0c6b44-45a1-401f-8541-d4dd9b28728a'
      },
      {
        aseguradora: this.aseguradoras[1].nombre,
        logo: this.aseguradoras[1].logo,
        plan: 'Medicvida Internacional',
        prima: Math.round(this.getTcea(this.medicVidaInternacionalSumFam, this.tceaPacifico)),
        categoria: 'Internacional',
        edadTitular: this.edadTitular,
        costoTitular: Math.round(this.getTcea(this.medicVidaInternacionalArr[0], this.tceaPacifico)),
        familiares: this.medicVidaInternacionalFam,
        coberturas: this.getCoberturas('medicvidaInternacional'),
        exclusiones: this.getExclusiones('medicvidaInternacional'),
        clinicas: this.getClinicas('medicvidaInternacional'),
        cartilla: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/cartillasPdf%2Fsalud%2FmedicvidaInternacional.pdf?alt=media&token=0e221734-f3bf-4b3c-b261-b2b805dbf34a',
        condicionado: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/condicionadosPdf%2Fsalud%2FmedicvidaInternacional.pdf?alt=media&token=6634decd-bd49-47f7-9f5d-7d5c1fce1589'
      },
      {
        aseguradora: this.aseguradoras[1].nombre,
        logo: this.aseguradoras[1].logo,
        plan: 'Medicvida Nacional',
        prima: Math.round(this.getTcea(this.medicvidaNacionalSumFam, this.tceaPacifico)),
        categoria: 'Premium',
        edadTitular: this.edadTitular,
        costoTitular: Math.round(this.getTcea(this.medicvidaNacionalArr[0], this.tceaPacifico)),
        familiares: this.medicvidaNacionalFam,
        coberturas: this.getCoberturas('medicvidaNacional'),
        exclusiones: this.getExclusiones('medicvidaNacional'),
        clinicas: this.getClinicas('medicvidaNacional'),
        cartilla: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/cartillasPdf%2Fsalud%2FmedicvidaNacional.pdf?alt=media&token=d518ecd9-11c8-4009-9da9-07ee0a7bacf8',
        condicionado: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/condicionadosPdf%2Fsalud%2FmedicvidaNacional.pdf?alt=media&token=ae3dcda4-e3d4-412e-87a1-343677dde42c'
      },
      {
        aseguradora: this.aseguradoras[1].nombre,
        logo: this.aseguradoras[1].logo,
        plan: 'Multi Salud',
        prima: Math.round(this.getTcea(this.multiSaludSumFam, this.tceaPacifico)),
        categoria: 'Avanzado',
        edadTitular: this.edadTitular,
        costoTitular: Math.round(this.getTcea(this.multiSaludArr[0], this.tceaPacifico)),
        familiares: this.multiSaludFam,
        coberturas: this.getCoberturas('multisalud'),
        exclusiones: this.getExclusiones('multisalud'),
        clinicas: this.getClinicas('multisalud'),
        cartilla: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/cartillasPdf%2Fsalud%2Fmultisalud.pdf?alt=media&token=eceeaaa8-2b0a-411e-aa9b-069e030158fb',
        condicionado: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/condicionadosPdf%2Fsalud%2Fmultisalud.pdf?alt=media&token=41e6572d-b0a3-48f2-a3f3-42772796b3de'
      },
      {
        aseguradora: this.aseguradoras[1].nombre,
        logo: this.aseguradoras[1].logo,
        plan: 'Red Preferente',
        prima: Math.round(this.getTcea(this.redPreferentePacSumFam, this.tceaPacifico)),
        categoria: 'Básico',
        edadTitular: this.edadTitular,
        costoTitular: Math.round(this.getTcea(this.redPreferentePacArr[0], this.tceaPacifico)),
        familiares: this.redPreferentePacFam,
        coberturas: this.getCoberturas('redPreferentePac'),
        exclusiones: this.getExclusiones('redPreferentePac'),
        clinicas: this.getClinicas('redPreferentePac'),
        cartilla: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/cartillasPdf%2Fsalud%2FredPreferentePac.pdf?alt=media&token=02f9fa45-1c37-4b71-97d1-461ef0a4ba33',
        condicionado: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/condicionadosPdf%2Fsalud%2FredPreferentePac.pdf?alt=media&token=6234cad4-f6e9-48e0-bb56-ac4436c31ca2'
      },
      {
        aseguradora: this.aseguradoras[2].nombre,
        logo: this.aseguradoras[2].logo,
        plan: 'Viva Salud',
        prima: Math.round(this.getTcea(this.vivaSaludSumFam, this.tceaMapfre)),
        categoria: 'Básico',
        edadTitular: this.edadTitular,
        costoTitular: Math.round(this.getTcea(this.vivaSaludArr[0], this.tceaMapfre)),
        familiares: this.vivaSaludFam,
        coberturas: this.getCoberturas('vivaSalud'),
        exclusiones: this.getExclusiones('vivaSalud'),
        clinicas: this.getClinicas('vivaSalud'),
        cartilla: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/cartillasPdf%2Fsalud%2FvivaSalud.pdf?alt=media&token=b4dd8b91-5f11-4b74-a7f0-e4ac6a4dcda3',
        condicionado: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/condicionadosPdf%2Fsalud%2FvivaSalud.pdf?alt=media&token=2d0e103b-3f35-4b46-8191-805649266868'
      },
      {
        aseguradora: this.aseguradoras[2].nombre,
        logo: this.aseguradoras[2].logo,
        plan: 'Viva Salud Plus',
        prima: Math.round(this.getTcea(this.vivaSaludPlusSumFam, this.tceaMapfre)),
        categoria: 'Avanzado',
        edadTitular: this.edadTitular,
        costoTitular: Math.round(this.getTcea(this.vivaSaludPlusArr[0], this.tceaMapfre)),
        familiares: this.vivaSaludPlusFam,
        coberturas: this.getCoberturas('vivaSaludPlus'),
        exclusiones: this.getExclusiones('vivaSaludPlus'),
        clinicas: this.getClinicas('vivaSaludPlus'),
        cartilla: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/cartillasPdf%2Fsalud%2FvivaSaludPlus.pdf?alt=media&token=ed640ed7-76cd-4f28-ac76-e9c2467caaa9',
        condicionado: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/condicionadosPdf%2Fsalud%2FvivaSaludPlus.pdf?alt=media&token=deec04d3-e713-4970-bb0a-80cf8f8472ce'
      },
      {
        aseguradora: this.aseguradoras[2].nombre,
        logo: this.aseguradoras[2].logo,
        plan: 'Trebol Salud',
        prima: Math.round(this.getTcea(this.trebolSaludSumFam, this.tceaMapfre)),
        categoria: 'Premium',
        edadTitular: this.edadTitular,
        costoTitular: Math.round(this.getTcea(this.trebolSaludArr[0], this.tceaMapfre)),
        familiares: this.trebolSaludFam,
        coberturas: this.getCoberturas('trebolSalud'),
        exclusiones: this.getExclusiones('trebolSalud'),
        clinicas: this.getClinicas('trebolSalud'),
        cartilla: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/cartillasPdf%2Fsalud%2FtrebolSalud.pdf?alt=media&token=78d3aa50-d3a2-4e09-95df-92bf708b2680',
        condicionado: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/condicionadosPdf%2Fsalud%2FtrebolSalud.pdf?alt=media&token=db1eeebd-b210-4287-a528-d78cc6615cd6'
      },
      // {
      //   aseguradora: this.aseguradoras[2].nombre,
      //   logo: this.aseguradoras[2].logo,
      //   plan: 'Previsor',
      //   prima: this.previsorSumFam,
      // },
      {
        aseguradora: this.aseguradoras[3].nombre,
        logo: this.aseguradoras[3].logo,
        plan: 'Medisalud Global',
        prima: Math.round(this.medisaludGlobalSumFam / 12),
        categoria: 'Internacional',
        edadTitular: this.edadTitular,
        costoTitular: Math.round(this.medisaludGlobalArr[0] / 12),
        familiares: this.medisaludGlobalFam,
        coberturas: this.getCoberturas('medisaludGlobal'),
        exclusiones: this.getExclusiones('medisaludGlobal'),
        clinicas: this.getClinicas('medisaludGlobal'),
        cartilla: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/cartillasPdf%2Fsalud%2FmedisaludGlobal.pdf?alt=media&token=2d67c7e1-5d69-42b4-957f-5d51c161bdc7',
        condicionado: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/condicionadosPdf%2Fsalud%2FmedisaludGlobal.pdf?alt=media&token=020ee374-55d5-41c1-adf2-beba68591724'
      },
      {
        aseguradora: this.aseguradoras[3].nombre,
        logo: this.aseguradoras[3].logo,
        plan: 'Medisalud Premium',
        prima: Math.round(this.medisaludPremiumSumFam / 12),
        categoria: 'Premium',
        edadTitular: this.edadTitular,
        costoTitular: Math.round(this.medisaludPremiumArr[0] / 12),
        familiares: this.medisaludPremiumFam,
        coberturas: this.getCoberturas('medisaludPremium'),
        exclusiones: this.getExclusiones('medisaludPremium'),
        clinicas: this.getClinicas('medisaludPremium'),
        cartilla: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/cartillasPdf%2Fsalud%2FmedisaludPremium.pdf?alt=media&token=d7118ae3-eaca-4374-9103-0eb9e867d935',
        condicionado: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/condicionadosPdf%2Fsalud%2FmedisaludPremium.pdf?alt=media&token=9acde3d3-f94d-42e4-b7c5-fe491f4b3be4'
      },
      {
        aseguradora: this.aseguradoras[3].nombre,
        logo: this.aseguradoras[3].logo,
        plan: 'Medisalud Plus',
        prima: Math.round(this.medisaludPlusSumFam / 12),
        categoria: 'Avanzado',
        edadTitular: this.edadTitular,
        costoTitular: Math.round(this.medisaludPlusArr[0] / 12),
        familiares: this.medisaludPlusFam,
        coberturas: this.getCoberturas('medisaludPlus'),
        exclusiones: this.getExclusiones('medisaludPlus'),
        clinicas: this.getClinicas('medisaludPlus'),
        cartilla: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/cartillasPdf%2Fsalud%2FmedisaludPlus.pdf?alt=media&token=8449bfd1-e734-465e-8843-41bee2b0b65e',
        condicionado: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/condicionadosPdf%2Fsalud%2FmedisaludPlus.pdf?alt=media&token=79489404-325a-4360-bd10-e6dcb01ed388'
      },
      {
        aseguradora: this.aseguradoras[3].nombre,
        logo: this.aseguradoras[3].logo,
        plan: 'Medisalud',
        prima: Math.round(this.medisaludSumFam / 12),
        categoria: 'Básico',
        edadTitular: this.edadTitular,
        costoTitular: Math.round(this.medisaludArr[0] / 12),
        familiares: this.medisaludFam,
        coberturas: this.getCoberturas('medisalud'),
        exclusiones: this.getExclusiones('medisalud'),
        clinicas: this.getClinicas('medisalud'),
        cartilla: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/cartillasPdf%2Fsalud%2Fmedisalud.pdf?alt=media&token=9281d2fe-11bd-4660-b6b2-be1272b0fcab',
        condicionado: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/condicionadosPdf%2Fsalud%2Fmedisalud.pdf?alt=media&token=e838ab88-9725-4cbb-bf38-d9cedcc2466d'
      },
    ];
  }

  primasResultOrder() {
    this.primasResultSort = of(this.primasResult.sort((a, b) => a.prima < b.prima ? -1 : a.prima > b.prima ? 1 : 0));
    // this.primasResult = of(this.primasSalud.sort((a, b) => a.total < b.total ? -1 : a.total > b.total ? 1 : 0));
  }

  getPrimasAllFilter() {
    const primasBasicoFilter = this.primasResult.filter( fil => fil.categoria === 'Básico' );
    this.primasBasico = of(primasBasicoFilter.sort((a, b) => a.prima < b.prima ? -1 : a.prima > b.prima ? 1 : 0));
    
    const primasAvanzadoFilter = this.primasResult.filter( fil => fil.categoria === 'Avanzado' );
    this.primasAvanzado = of(primasAvanzadoFilter.sort((a, b) => a.prima < b.prima ? -1 : a.prima > b.prima ? 1 : 0));

    const primasPremiumFilter = this.primasResult.filter( fil => fil.categoria === 'Premium' );
    this.primasPremium = of(primasPremiumFilter.sort((a, b) => a.prima < b.prima ? -1 : a.prima > b.prima ? 1 : 0));

    const primasInternacionalFilter = this.primasResult.filter( fil => fil.categoria === 'Internacional' );
    this.primasInternacional = of(primasInternacionalFilter.sort((a, b) => a.prima < b.prima ? -1 : a.prima > b.prima ? 1 : 0));

  }

  errorNombre() {
    return this.formSalud.controls.nombre.hasError('required') ? 'Ingrese un nombre' :
        '';
  }

  errorEmail() {
    return this.formSalud.controls.email.errors?.required ? 'Ingrese un email' :
      this.formSalud.controls.email.errors?.email ? 'Ingrese un email válido' :
        '';
  }

  errorCelular() {
    return this.formSalud.controls.celular.hasError('required') ? 'Ingrese el número de celular' :
    this.formSalud.controls.celular.hasError('pattern') ? 'Solo se admiten números' :
    this.formSalud.controls.celular.hasError('minlength') ? 'Debe contener 9 dígitos' :
    this.formSalud.controls.celular.hasError('maxlength') ? 'No debe exceder los 9 dígitos' :
    '';
  }

  errorEdadTitular() {
    return this.formSalud.controls.edadTitular.hasError('required') ? 'Selecciona la edad del titular' : '';
  }

}
