<div class="p-3 p-md-4">

  <div class="buttonClose">
    <button mat-icon-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-title class="mb-2" >

    <div class="me-4">
      <p class="h4">Principales clínicas y copagos</p>
    </div>

    <!-- <div class="border rounded py-2 pe-3 ps-1">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="">
          <img class="logoResultadoSalud" [src]="data.logo">
        </div>
        <div class="ms-2">
          <p class="planSalud mb-0">{{data.plan}}</p>
        </div>
        <div class="text-end ms-3 ms-md-5">
          <p class="primaSalud mb-0">S/ {{data.prima | number: '1.0-0'}}</p>
          <p class="extraSmall text-muted mb-0 lh-sm" style="white-space: nowrap;">al mes</p>
        </div>
      </div>
    </div> -->
    
    <p class="extraSmall mb-0 text-muted lh-sm">{{data.plan}} &#183; {{data.aseguradora}} &#183; S/ {{data.prima | number: '1.0-0'}} &#183; Ver detalle completo en: <a [href]="data.cartilla" target="_blank">Cartilla PDF</a></p>

  </div>

  <!-- <mat-dialog-content class="py-3"> -->

  <!-- <div class="  border-top border-bottom py-2">
      <div class="d-flex justify-content-between w-100">
        <div class="">
          <img class="logoResultadoSalud" [src]="data.logo">
        </div>
        <div>
          <p class="planSalud mb-0">{{data.plan}}</p>
        </div>
        <div class="text-end ms-3 ms-md-5">
          <p class="primaSalud mb-0">S/ {{data.prima | number: '1.0-0'}}</p>
          <p class="small text-muted mb-0" style="white-space: nowrap;">al mes</p>
        </div>
      </div>
    </div> -->

  <mat-dialog-content class="py-1">

    <!-- <mat-tab-group class="myTab">
      <mat-tab *ngFor="let item of data.clinicas" [label]="item.tipo">
        <table class="table extraSmall">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Copago</th>
              <th scope="col">Cobertura</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let clinica of item.establecimientos">
              <td>{{clinica.clinica}}</td>
              <td class="text-nowrap">{{clinica.copago}}</td>
              <td class="text-nowrap">{{clinica.cobertura}}</td>
            </tr>
          </tbody>
        </table>
      </mat-tab>
    </mat-tab-group> -->

    <mat-accordion>
      <app-card-clinica *ngFor="let item of data.clinicas; let primero = first; let ultimo = last" [ngClass]="{ 'primerItemAcordion': primero, 'ultimoItemAcordion': ultimo }" [item]="item"></app-card-clinica>
    </mat-accordion>

  </mat-dialog-content>

  




</div>