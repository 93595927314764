<div class="myPositionBottom">
	<a class="btn btn-secondary btn-circle-lg shadow-sm"
		href="https://api.whatsapp.com/send?phone=51950831391&text=Hola, te escribo desde la web de Seguros 360 necesito que me asesoren con un seguro..."
		target="blank">
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-whatsapp" width="32" height="32"
			viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round"
			stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none" />
			<path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
			<path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1" />
		</svg>
	</a>
</div>

<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
