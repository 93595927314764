import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { Noticia } from 'src/app/classes/noticia';

@Component({
  selector: 'app-detalle-noticia',
  templateUrl: './detalle-noticia.component.html',
  styleUrls: ['./detalle-noticia.component.scss']
})
export class DetalleNoticiaComponent implements OnInit {

  noticia: Noticia;

  constructor(
    private activatedRoute: ActivatedRoute,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe( res => {
      const noticiaId = res.id;

      this.afs.doc(`noticias/${noticiaId}`).valueChanges().subscribe( data => {
        this.noticia = data;
      });

    });

  }

}
