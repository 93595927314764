<div class="bg-light">
  <div class="container py-4 py-md-6">

    <p class="h1 mb-4 mb-md-5">Términos y Condiciones</p>

    <div class="mb-4 mb-md-5 small">
      <p>Eagles Corredores de Seguros S.A.C (en adelante, la “Empresa”) da la bienvenida a los usuarios de Internet que visitan nuestra página web y espera que la información puesta a su disposición sea de utilidad.</p>
      <p>Los usuarios de nuestra página web, al acceder y hacer uso de la misma declaran aceptar los términos y condiciones que a continuación se indican. Si el usuario no está de acuerdo con los términos y condiciones que aparecen a continuación, deberá abstenerse de ingresar a esta página web ni a ninguna de sus páginas.</p>
      <p>A continuación, se exponen dichas condiciones:</p>
      <ol class="listaTerminosCondiciones">
        <li>Toda la información mostrada a través de este medio es referencial; por lo que el consumidor deberá informarse de las condiciones vigentes al día de la contratación.</li>
        <li>Los derechos de propiedad intelectual (nombres comerciales, marcas, lemas, encabezados de páginas, gráficos, textos, logos, botones, íconos, imágenes, audio, código y software) incorporados a esta página web son de propiedad exclusiva de la Empresa. Su uso, disposición o integración están protegidos por la legislación de la materia. Se prohíbe cualquier otro uso, incluyendo la reproducción, modificación, distribución, transmisión, exhibición o impresión, salvo autorización escrita de la Empresa.</li>
        <li>El usuario reconoce que las disposiciones establecidas en el párrafo anterior también son aplicables a los derechos de propiedad intelectual de terceros cuyas páginas web estén enlazadas a la página web de la Empresa.</li>
        <li>La Empresa, es un intermediario autorizado por la SBS para el corretaje de seguros (Reg. SBS J-0658). Las coberturas para todos los seguros son otorgadas por las compañías de seguros respectivas.</li>
        <li>
          <p>Lea esta sección detenidamente. Esta sección limita la responsabilidad de la Empresa ante usted en cuanto a problemas que pudieran surgir en relación con el uso de la plataforma, redes, páginas webs, aplicaciones o servicios prestados por la Empresa. Si no comprende las condiciones de esta sección o de cualquier parte del documento, consulte a un abogado para que se los aclare antes de acceder o usar los servicios.</p>
          <p>La Empresa no garantiza la inexistencia de interrupciones o errores en el acceso al sitio web o a su contenido, ni que este se encuentre actualizado. Al hacer uso de la página web, usted reconoce y acepta la probable existencia de estos errores, y asume el riesgo de continuar adelante con su uso.</p>
          <p>La Empresa no responderá de ninguna consecuencia, daño o perjuicio que pudieran derivarse de su acceso o uso. Tampoco se hace responsable de los errores de seguridad, que se puedan producir ni de los daños que puedan producirse en el sistema informático; como consecuencia de (i) la presencia de un software malicioso en sus ordenadores que sea utilizado para la conexión a los contenidos de la Empresa; (ii) un mal funcionamiento del navegador; (iii) uso de versiones no actualizadas del navegador.</p>
          <p>La Empresa no asume ninguna responsabilidad derivada de los contenidos enlazados desde la plataforma, siempre que sean ajenos al mismo, ni garantiza la ausencia de software malicioso u otros elementos en los mismos que puedan producir alteraciones en el sistema informático (hardware y software).</p>
          <p>Como usuario de los Servicios, usted entiende y acepta que la Empresa no tendrá responsabilidad con usted por cualquier uso que haga de la pagina web.</p>
          <p>Estos términos y condiciones y la anterior exención de responsabilidad no afectan los derechos legales obligatorios que no pueden excluirse según la ley aplicable; por ejemplo, las leyes de protección del consumidor.</p>
        </li>
        <li>La Empresa podrá actualizar, modificar, aumentar, disminuir, cambiar o corregir cualquier información u omisión contenida en esta página web, así como los términos y condiciones aquí descritos, en cualquier momento y sin previo aviso a los usuarios/clientes. Dichos cambios serán reflejados en la presente página web, y entrarán en vigencia desde la fecha de su publicación o desde la fecha que se indique para tal efecto, no siendo necesario notificación al cliente de ninguna otra forma.</li>
        <li>La Empresa atenderá las quejas y reclamos en un plazo máximo de treinta (30) días de presentado.</li>
        <li>El acceso y uso de esta página web se rige por los términos y condiciones aquí descritos, las disposiciones legales aplicables a la materia y cualquier otro instrumento suscrito por el usuario/cliente, en lo que le sea aplicable.</li>
        <li>Toda controversia, discrepancia e infracción de los términos y condiciones establecidos en el presente documento serán sometidas a los jueces y tribunales competentes del Distrito Judicial de Lima - Cercado.</li>
      </ol>
    </div>
 

    
  </div>
</div>