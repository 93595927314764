import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Timestamp } from '@angular/fire/firestore';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-libro-reclamaciones',
  templateUrl: './libro-reclamaciones.component.html',
  styleUrls: ['./libro-reclamaciones.component.scss']
})
export class LibroReclamacionesComponent implements OnInit {

  formReclamo: UntypedFormGroup;
  loading: boolean;
  tipoReclamos = [
    {
      nombre: 'Reclamo',
      descripcion: 'Disconformidad relacionada a los productos o servicios.'
    },
    {
      nombre: 'Queja',
      descripcion: 'Malestar o descontento respecto a la atención al público.'
    },
  ];
  tipoDocumentos = [
    'DNI',
    'Pasaporte',
    'RUC',
    'Carnet de extranjería',
    'Carnet de recién nacido'
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private afs: AngularFirestore,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.formReclamo = this.fb.group({
      tipoReclamo: ['', Validators.required],
      tipoDocumento: ['DNI', Validators.required],
      numeroDocumento: ['', Validators.required],
      nombreRazonSocial: ['', Validators.required],
      email: ['', Validators.required],
      telefono: ['', Validators.required],
      domicilio: ['', Validators.required],
      tipoSeguro: ['', Validators.required],
      detalle: ['', Validators.required],
      pedido: ['', Validators.required],
      fechaCreacion: [ Timestamp.now()],
    });
  }

  onSubmit() {
    if (this.formReclamo.valid) {
      this.crearReclamo();
      this.loading = true;
    } else {
      this.validateAllFormFields(this.formReclamo);
      this.loading = false;
    }
  }

  crearReclamo() {
    this.afs.collection('reclamos').add(this.formReclamo.value)
    .then( () => {
      this.router.navigate(['/graciasReclamo'])
    })



    // this.fs.addReclamo(this.formReclamo.value)
    //   .then(() => {
    //     this.snackBar.open('Reclamo enviado', 'CERRAR', {
    //       duration: 3000,
    //     });
    //     this.form.resetForm({
    //       fechaCreacion: firebase.firestore.FieldValue.serverTimestamp(),
    //     });
    //     this.loading = false;
    //   });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
