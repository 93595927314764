<div class="">

    <div class="">
      <div class="bg-primary p-3 p-md-5 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <p class="h2 text-white">Cotiza tu seguro de salud al instante</p>
          <p class="text-white">Compara los precios entre las principales aseguradoras</p>
        </div>
      </div>
    </div>

    <div class="bg-light">
      <div class="container p-3 p-md-5 d-flex justify-content-center">
        <div class="col-md-6">
          <app-form-cotizador-salud [pedidoId]="cotizacionId" (emitirValoresSalud)="recibirValoresSalud($event)"></app-form-cotizador-salud>
        </div>
      </div>
    </div>
 
</div>