<div class="border-bottom">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <a routerLink="/">
          <img class="logoSeguros360 my-3 my-md-4"
            src="https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/logoSeguros360.svg?alt=media&token=88975f30-8b60-421c-ae91-1cf125f4be5f"
            alt="">
        </a>
      </div>

      <div class="">



        <div class="d-none d-md-inline-block">

          <a class="me-4" [routerLink]="item.url" *ngFor="let item of links">{{item.nombre}}</a>

          <a class="btn btn-outline-secondary btn-circle me-1"
            href="https://api.whatsapp.com/send?phone=51950831391&text=Hola, te escribo desde la web de Seguros 360 necesito que me asesoren con un seguro..."
            target="blank">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-whatsapp" width="28"
              height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#1446A3" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
              <path
                d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1" />
            </svg>
          </a>

          <a class="btn btn-outline-secondary btn-circle me-1" href="tel:950831391">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone" width="28" height="28"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="#1446A3" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" />
              <path
                d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
            </svg>
          </a>

          <div class="d-inline-flex gap-2">
            <!-- <a class="btn btn-outline-primary myBtn" routerLink='.' fragment="soat" >COMPRAR SOAT</a> -->
            <a class="btn btn-outline-primary myBtn" routerLink='/soat'>Compra tu SOAT</a>
            <button class="btn btn-secondary myBtn" (click)="openBottomSheetTourFijos()">COTIZAR</button>
          </div>
        
        </div>


        <div class="d-inline-block d-md-none">

          <a class="btn btn-outline-primary btn-sm me-1" routerLink='.' fragment="soat" >SOAT</a>

          <button mat-icon-button (click)="openBottomSheetTourFijos()">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-calculator" width="28"
              height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FA391F" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <rect x="4" y="3" width="16" height="18" rx="2" />
              <rect x="8" y="7" width="8" height="3" rx="1" />
              <line x1="8" y1="14" x2="8" y2="14.01" />
              <line x1="12" y1="14" x2="12" y2="14.01" />
              <line x1="16" y1="14" x2="16" y2="14.01" />
              <line x1="8" y1="17" x2="8" y2="17.01" />
              <line x1="12" y1="17" x2="12" y2="17.01" />
              <line x1="16" y1="17" x2="16" y2="17.01" />
            </svg>
          </button>

          <button mat-icon-button type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/iconMenuMobile.svg?alt=media&token=c364cd96-13ba-4029-93c9-1fabcf54e0cd"
              alt="">
          </button>

          <div class="myOffCanva">
            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight"
              aria-labelledby="offcanvasRightLabel">
              <div class="offcanvas-header d-flex justify-content-end">
                <!-- <h5 id="offcanvasRightLabel">Offcanvas right</h5> -->
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
              </div>
              <div class="offcanvas-body">
                <div class="d-flex flex-column ps-3">
                  <a class="my-2 lead" [routerLink]="link.url" *ngFor="let link of links"
                    data-bs-dismiss="offcanvas">{{link.nombre}}</a>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>

  </div>
</div>