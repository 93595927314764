import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';


// Angularfire2
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAnalytics,getAnalytics,ScreenTrackingService,UserTrackingService } from '@angular/fire/analytics';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideStorage,getStorage } from '@angular/fire/storage';


// Tabler icons
import { TablerIconsModule } from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';


// Angular Material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PublicComponent } from './components/public/public/public.component';
import { InicioComponent } from './components/public/inicio/inicio.component';
import { HeaderComponent } from './components/public/header/header.component';
import { FooterComponent } from './components/public/footer/footer.component';
// import { AdminComponent } from './admin/admin/admin.component';
import { CotizadoresComponent } from './components/public/cotizadores/cotizadores.component';
import { LoginComponent } from './components/public/login/login.component';
import { CotizadorSaludComponent } from './components/public/cotizadores/cotizador-salud/cotizador-salud.component';
// import { InicioAdminComponent } from './admin/inicio-admin/inicio-admin.component';
import { CategoriaComponent } from './components/public/categoria/categoria.component';
import { FormContactoComponent } from './components/public/cotizadores/form-contacto/form-contacto.component';
import { ModalTerminosComponent } from './components/public/modal-terminos/modal-terminos.component';
import { FormCotizadorSaludComponent } from './components/public/cotizadores/cotizador-salud/form-cotizador-salud/form-cotizador-salud.component';
import { CardResultadoSaludComponent } from './components/public/cotizadores/cotizador-salud/card-resultado-salud/card-resultado-salud.component';
import { ClinicasComponent } from './components/public/cotizadores/cotizador-salud/clinicas/clinicas.component';
import { CoberturasSaludComponent } from './components/public/cotizadores/cotizador-salud/coberturas-salud/coberturas-salud.component';
import { MeInteresaComponent } from './components/public/cotizadores/me-interesa/me-interesa.component';
import { CartillaSaludComponent } from './components/public/cotizadores/cotizador-salud/cartilla-salud/cartilla-salud.component';
import { ResultadosSaludComponent } from './components/public/cotizadores/cotizador-salud/resultados-salud/resultados-salud.component';
import { CardClinicaComponent } from './components/public/cotizadores/cotizador-salud/card-clinica/card-clinica.component';
import { SegurosComponent } from './components/public/seguros/seguros.component';
import { FamiliaComponent } from './components/public/seguros/familia/familia.component';
import { SeguroDetalleComponent } from './components/public/seguros/seguro-detalle/seguro-detalle.component';
import { NosotrosComponent } from './components/public/nosotros/nosotros.component';
import { SociosComponent } from './components/public/socios/socios.component';
import { ContactoComponent } from './components/public/contacto/contacto.component';
import { CotizadorSoatAutoComponent } from './components/public/cotizadores/cotizador-soat-auto/cotizador-soat-auto.component';
import { CardSoatAutoComponent } from './components/public/cotizadores/cotizador-soat-auto/card-soat-auto/card-soat-auto.component';
import { LibroReclamacionesComponent } from './components/public/libro-reclamaciones/libro-reclamaciones.component';
import { GraciasReclamoComponent } from './components/public/gracias-reclamo/gracias-reclamo.component';
import { TerminosCondicionesComponent } from './components/public/terminos-condiciones/terminos-condiciones.component';
import { PoliticaPrivacidadComponent } from './components/public/politica-privacidad/politica-privacidad.component';
import { ModalCotizadoresComponent } from './components/public/modal-cotizadores/modal-cotizadores.component';
import { VehicularComponent } from './components/public/cotizadores/vehicular/vehicular.component';
import { CotizadorVidaComponent } from './components/public/cotizadores/cotizador-vida/cotizador-vida.component';
import { GraciasSeguroVidaComponent } from './components/public/cotizadores/gracias-seguro-vida/gracias-seguro-vida.component';
import { GraciasVehicularComponent } from './components/public/cotizadores/vehicular/gracias-vehicular/gracias-vehicular.component';
// import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
// import { provideFunctions,getFunctions } from '@angular/fire/functions';
import { AngularFireModule, FIREBASE_APP_NAME, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { SoatComponent } from './components/public/soat/soat.component';
import { provideMessaging,getMessaging } from '@angular/fire/messaging';
import { providePerformance,getPerformance } from '@angular/fire/performance';
import { provideRemoteConfig,getRemoteConfig } from '@angular/fire/remote-config';
import { NoticiasBlockComponent } from './components/public/noticias-block/noticias-block.component';
import { DetalleNoticiaComponent } from './components/public/detalle-noticia/detalle-noticia.component';
import { SafePipe } from './pipes/safe.pipe';
import { BlogComponent } from './components/public/blog/blog.component';


@NgModule({
  declarations: [
    AppComponent,
    PublicComponent,
    InicioComponent,
    HeaderComponent,
    FooterComponent,
    // AdminComponent,
    CotizadoresComponent,
    LoginComponent,
    CotizadorSaludComponent,
    // InicioAdminComponent,
    CategoriaComponent,
    FormContactoComponent,
    ModalTerminosComponent,
    FormCotizadorSaludComponent,
    CardResultadoSaludComponent,
    ClinicasComponent,
    CoberturasSaludComponent,
    MeInteresaComponent,
    CartillaSaludComponent,
    ResultadosSaludComponent,
    CardClinicaComponent,
    SegurosComponent,
    FamiliaComponent,
    SeguroDetalleComponent,
    NosotrosComponent,
    SociosComponent,
    ContactoComponent,
    CotizadorSoatAutoComponent,
    CardSoatAutoComponent,
    LibroReclamacionesComponent,
    GraciasReclamoComponent,
    TerminosCondicionesComponent,
    PoliticaPrivacidadComponent,
    ModalCotizadoresComponent,
    VehicularComponent,
    CotizadorVidaComponent,
    GraciasSeguroVidaComponent,
    GraciasVehicularComponent,
    SoatComponent,
    NoticiasBlockComponent,
    DetalleNoticiaComponent,
    SafePipe,
    BlogComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideStorage(() => getStorage()),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSliderModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSlideToggleModule,
    MatIconModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatCardModule,
    MatTabsModule,
    MatToolbarModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatExpansionModule,
    MatTooltipModule,
    MatBadgeModule,
    MatBottomSheetModule,
    HttpClientModule,
    TablerIconsModule.pick(TablerIcons),
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance()),
    provideRemoteConfig(() => getRemoteConfig()),
  ],
  providers: [
    ScreenTrackingService, UserTrackingService,
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
    },
    
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
