<div class="py-3 py-md-4 px-2 px-md-3">
  <p class="h4 fw-light" style="margin-left: 16px;">¿Que quieres cotizar:</p>
  <mat-nav-list>
    <a mat-list-item [routerLink]=" 'cotizadores/' + cotizador.id " *ngFor="let cotizador of data" (click)="openLink($event)">
      <div class="d-flex justify-content-between align-items-center w-100 py-2">
        <div>
          <p class="mb-0 fw-bold">{{cotizador.nombre}}</p>
          <!-- <p class="mb-0 extraSmall">Desde PEN {{cotizador.precioAdulto}}</p> -->
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <polyline points="9 6 15 12 9 18" />
          </svg>
        </div>
      </div>
      </a>
  </mat-nav-list>
</div>