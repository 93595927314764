import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { Noticia } from '../classes/noticia';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private afs: AngularFirestore,
  ) { }

  getPrimasSaludAdulto() {
    return this.afs.collection('primasSaludAdulto', ref => ref
    .orderBy('edad', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
  );
  }

  getPrimasSaludConyuge() {
    return this.afs.collection('primasSaludConyuge', ref => ref
    .orderBy('edad', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
  );
  }

  getPrimasSaludHijo() {
    return this.afs.collection('primasSaludHijo', ref => ref
    .orderBy('edad', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
  );
  }

  getPlanesSalud() {
    return this.afs.collection('planesSalud').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }


  getPrimasSoatAutos() {
    return this.afs
      .collection('primasSoatAutos', (ref) => ref
      .orderBy('marca', 'asc')
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getAllNoticias() {
    return this.afs.collection('noticias', ref => ref
      .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Noticia;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getNoticiasHome() {
    return this.afs.collection('noticias', ref => ref
    .where('publicado', '==', true)
    .orderBy('fecha', 'desc')
    .limit(2)
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Noticia;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getNoticiasBlog() {
    return this.afs.collection('noticias', ref => ref
    .where('publicado', '==', true)
    .orderBy('fecha', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Noticia;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }


}
