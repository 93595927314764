import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { Vehicular } from 'src/app/classes/vehicular';

@Component({
  selector: 'app-gracias-vehicular',
  templateUrl: './gracias-vehicular.component.html',
  styleUrls: ['./gracias-vehicular.component.scss']
})
export class GraciasVehicularComponent implements OnInit {

  idVehicular: string;
  vehicular: Vehicular;


  constructor(
    private activatedRoute: ActivatedRoute,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe( params => {
      this.idVehicular = params.id;
      this.afs.collection('cotizacionesVehicular').doc(this.idVehicular).valueChanges().subscribe( (res: Vehicular) => {
        this.vehicular = res;
      });
    });
    
  }

}
