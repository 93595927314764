import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vehicular',
  templateUrl: './vehicular.component.html',
  styleUrls: ['./vehicular.component.scss']
})
export class VehicularComponent implements OnInit {

  formVehicular: UntypedFormGroup;
  loading: boolean;
  vehicular: any;

  points = [
    'Auxilio mecánico',
    'Auto de reemplazo en caso de robo o choque',
    'Cobertura de daños a terceros',
    'Chofer de reemplazo',
    'Choques',
    'Grúa',
    'Robo',
    'Y más...'
  ]

  constructor(
    private fb: UntypedFormBuilder,
    private afs: AngularFirestore,
    private router: Router
  ) {
    this.vehicular = this.afs.collection('cotizacionesVehicular').ref.doc();
      console.log(this.vehicular.id);
  }

  ngOnInit(): void {
    this.formVehicular = this.fb.group({
      marca: ['', Validators.required],
      modelo: ['', Validators.required],
      zonaCirculacion: ['', Validators.required],
      anioFabricacion: ['', Validators.required],
      valorVehiculo: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      celular: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(9), Validators.minLength(9)]],
      edad: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.formVehicular.valid) {
      this.loading = true;
      this.crearCotizacionVehicular();
    } else {
      this.validateAllFormFields(this.formVehicular)
    }
  }

  crearCotizacionVehicular() {
    this.vehicular.set(this.formVehicular.value)
    .then(() => {
      this.router.navigate([`/cotizadores/vehicular/gracias/${this.vehicular.id}`]);
      console.log('Gremio creado');
    });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
