<!-- <div class="backCotizador">
  <div class="container">
    <div class="row">
      <div class="col-md-6 bg-primary">
        <div class="text-white p-4 py-md-7">
          <p class="h1 mb-4">Cotiza tu seguro de salud al instante</p>
          <div class="d-flex mb-2" *ngFor="let item of points">
            <span class="me-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#06AB3E" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="12" cy="12" r="9" />
                <path d="M9 12l2 2l4 -4" />
              </svg>
            </span>
            <div class="pt-1">
              <span class="fw-light">{{item}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6  bg-light">
        <div class="p-4 ps-md-7 py-md-7 pe-md-0">
          <p class="h5">Datos de contacto</p>
          <form [formGroup]="formSalud" (ngSubmit)="onSubmit()" novalidate class="row">
            <mat-form-field appearance="outline">
              <mat-label>Nombre</mat-label>
              <input matInput formControlName="nombre">
              <mat-error *ngIf="errorNombre()">{{errorNombre()}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email">
              <mat-error *ngIf="errorEmail()">{{errorEmail()}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Celular</mat-label>
              <input matInput formControlName="celular">
              <mat-error *ngIf="errorCelular()">{{errorCelular()}}</mat-error>
            </mat-form-field>
            <p class="h5">¿A quién vamos a asegurar?</p>
            <mat-form-field appearance="outline" class="col-12">
              <mat-label>Edad del titular</mat-label>
              <mat-select formControlName="edadTitular">
                <mat-option *ngFor="let item of edades" [value]="item">
                  {{item}} años
                </mat-option>
              </mat-select>
              <mat-error *ngIf="errorEdadTitular()">{{errorEdadTitular()}}</mat-error>
            </mat-form-field>
            <div formArrayName="familiares" class="col-12">
              <div *ngFor="let familiar of formSalud.controls['familiares']['controls']; let i = index;">
                <div class="d-flex" [formGroupName]="i">
                  <div class="row w-100 g-3 g-md-4">
                    <mat-form-field appearance="outline" class="col-6">
                      <mat-label>Vínculo</mat-label>
                      <mat-select formControlName="vinculo">
                        <mat-option *ngFor="let vinculo of vinculos" [value]="vinculo">{{vinculo}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-6">
                      <mat-label>Edad</mat-label>
                      <mat-select formControlName="edad">
                        <mat-option *ngFor="let item of edades" [value]="item">{{item}} años</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="flex-shrink-1 ms-2" style="padding-top: 18px;">
                    <button mat-icon-button (click)="eliminarFamiliar(i)">
                      <mat-icon color="primary" class="material-icons-outlined">close</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <button class="btn btn-light btn-sm text-primary p-0" type="button" (click)="agregarFamiliar()"
                  [disabled]="this.formSalud.get('edadTitular').value === ''">
                  <mat-icon class="align-middle">add</mat-icon>
                  <span class="ml-1">
                    Agregar familiar
                  </span>
                </button>
              </div>
            </div>
            <div class="d-grid mt-2" *ngIf="!visible">
              <button class="btn btn-secondary myBtn" [disabled]="!formSalud.valid">
                <span class="d-flex justify-content-center" *ngIf="loading">
                  <mat-spinner class="text-center" [diameter]="24"></mat-spinner>
                </span>
                <span *ngIf="!loading">VER PRECIOS</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div> -->








<div class="backVida">
  <div class="container">
    <div class="row">

      <div class="col-md-6 bg-light">
        <div class="p-4 p-md-7 ps-md-0">
          <p class="display-4 fw-normal text-primary">¿Por qué un Seguro de Salud?</p>
          <div class="h5 fw-normal mb-4 mb-md-5">
            <p>El Seguro de Salud o seguro médico es un seguro que te brinda tranquilidad en el cuidado de tu salud porque te permitirá afrontar los gastos de una atención médica a consecuencia de una enfermedad y/o accidente.</p>
          </div>
          <a class="btn btn-outline-secondary"
          href="https://api.whatsapp.com/send?phone=51950831391&text=Hola, te escribo desde la web de Seguros 360, tengo una duda con el Seguro de Salud"
          target="blank">
          <span class="me-1">
            <i-tabler name="brand-whatsApp"></i-tabler>
          </span>
          <span>Conversemos</span>
        </a>
        </div>

      </div>

      <div class="col-md-6 bg-primary">
        <div class="p-3 py-5 p-md-7 pe-md-0">
          <div class="bg-white p-4 p-md-5 rounded-3">
            <p class="h5">Datos de contacto</p>
            <form [formGroup]="formSalud" (ngSubmit)="onSubmit()" novalidate class="row">
              <mat-form-field appearance="outline" class="col-12 col-md-6">
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="nombre">
                <mat-error *ngIf="errorNombre()">{{errorNombre()}}</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-12 col-md-6">
                <mat-label>Celular</mat-label>
                <input matInput formControlName="celular">
                <mat-error *ngIf="errorCelular()">{{errorCelular()}}</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email">
                <mat-error *ngIf="errorEmail()">{{errorEmail()}}</mat-error>
              </mat-form-field>
              
              <p class="h5">¿A quién vamos a asegurar?</p>
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>Edad del titular</mat-label>
                <mat-select formControlName="edadTitular">
                  <mat-option *ngFor="let item of edades" [value]="item">
                    {{item}} años
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="errorEdadTitular()">{{errorEdadTitular()}}</mat-error>
              </mat-form-field>
              <div formArrayName="familiares" class="col-12">
                <div *ngFor="let familiar of formSalud.controls['familiares']['controls']; let i = index;">
                  <div class="d-flex" [formGroupName]="i">
                    <div class="row w-100 g-3 g-md-4">
                      <mat-form-field appearance="outline" class="col-6">
                        <mat-label>Vínculo</mat-label>
                        <mat-select formControlName="vinculo">
                          <mat-option *ngFor="let vinculo of vinculos" [value]="vinculo">{{vinculo}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="col-6">
                        <mat-label>Edad</mat-label>
                        <mat-select formControlName="edad">
                          <mat-option *ngFor="let item of edades" [value]="item">{{item}} años</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="flex-shrink-1 ms-2" style="padding-top: 18px;">
                      <button mat-icon-button (click)="eliminarFamiliar(i)">
                        <mat-icon color="primary" class="material-icons-outlined">close</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <button class="btn btn-light btn-sm text-primary p-0" type="button" (click)="agregarFamiliar()"
                    [disabled]="this.formSalud.get('edadTitular').value === ''">
                    <mat-icon class="align-middle">add</mat-icon>
                    <span class="ml-1">
                      Agregar familiar
                    </span>
                  </button>
                </div>
              </div>
              <div class="d-grid mt-2" *ngIf="!visible">
                <button class="btn btn-secondary myBtn" [disabled]="!formSalud.valid">
                  <span class="d-flex justify-content-center" *ngIf="loading">
                    <mat-spinner class="text-center" [diameter]="24"></mat-spinner>
                  </span>
                  <span *ngIf="!loading">VER PRECIOS</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>










<div class="container">

  <div *ngIf="visible">

    <div class="mt-4 mt-md-5" *ngIf="this.formSalud.get('edadTitular').value !== '' ">
      <p class="h4 mb-0">Planes Básicos</p>
      <div class="myAlertSuccess">
        <div class="d-flex mb-1" *ngFor="let beneficio of beneficiosPlanBasico">
          <div class="me-2 d-flex align-items-start">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="20"
              height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#345e30" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="12" cy="12" r="9" />
              <path d="M9 12l2 2l4 -4" />
            </svg>
          </div>
          <p class="small mb-0 lh-1" style="margin-top: 2px;">{{beneficio}}</p>
        </div>
      </div>
    </div>
    <mat-accordion>
      <app-card-resultado-salud *ngFor="let item of primasBasico | async; let primero = first; let ultimo = last"
        [ngClass]="{ 'primerItemAcordion': primero, 'ultimoItemAcordion': ultimo }" [item]="item" [formSalud]="formSalud">
      </app-card-resultado-salud>
    </mat-accordion>
  
  
  
    <div class="mt-4 mt-md-5" *ngIf="this.formSalud.get('edadTitular').value !== ''">
      <p class="h4 mb-0">Planes Avanzados</p>
      <div class="myAlertSuccess">
        <div class="d-flex mb-1" *ngFor="let beneficio of beneficiosPlanAvanzado">
          <div class="me-2 d-flex align-items-start">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="20"
              height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#345e30" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="12" cy="12" r="9" />
              <path d="M9 12l2 2l4 -4" />
            </svg>
          </div>
          <p class="small mb-0 lh-1" style="margin-top: 2px;">{{beneficio}}</p>
        </div>
      </div>
    </div>
    <mat-accordion>
      <app-card-resultado-salud *ngFor="let item of primasAvanzado | async; let primero = first; let ultimo = last"
        [ngClass]="{ 'primerItemAcordion': primero, 'ultimoItemAcordion': ultimo }" [item]="item" [formSalud]="formSalud">
      </app-card-resultado-salud>
    </mat-accordion>
  
    <div class="mt-4 mt-md-5" *ngIf="this.formSalud.get('edadTitular').value !== ''">
      <p class="h4 mb-0">Planes Premium</p>
      <div class="myAlertSuccess">
        <div class="d-flex mb-1" *ngFor="let beneficio of beneficiosPlanPremium">
          <div class="me-2 d-flex align-items-start">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="20"
              height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#345e30" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="12" cy="12" r="9" />
              <path d="M9 12l2 2l4 -4" />
            </svg>
          </div>
          <p class="small mb-0 lh-1" style="margin-top: 2px;">{{beneficio}}</p>
        </div>
      </div>
    </div>
    <mat-accordion>
      <app-card-resultado-salud *ngFor="let item of primasPremium | async; let primero = first; let ultimo = last"
        [ngClass]="{ 'primerItemAcordion': primero, 'ultimoItemAcordion': ultimo }" [item]="item" [formSalud]="formSalud">
      </app-card-resultado-salud>
    </mat-accordion>
  
    <div class="mt-4 mt-md-5" *ngIf="this.formSalud.get('edadTitular').value !== ''">
      <p class="h4 mb-0">Planes Internacionales</p>
      <div class="myAlertSuccess">
        <div class="d-flex mb-1" *ngFor="let beneficio of beneficiosPlanInternacional">
          <div class="me-2 d-flex align-items-start">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="20"
              height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#345e30" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="12" cy="12" r="9" />
              <path d="M9 12l2 2l4 -4" />
            </svg>
          </div>
          <p class="small mb-0 lh-1" style="margin-top: 2px;">{{beneficio}}</p>
        </div>
      </div>
    </div>
    <mat-accordion>
      <app-card-resultado-salud *ngFor="let item of primasInternacional | async; let primero = first; let ultimo = last"
        [ngClass]="{ 'primerItemAcordion': primero, 'ultimoItemAcordion': ultimo }" [item]="item" [formSalud]="formSalud">
      </app-card-resultado-salud>
    </mat-accordion>
  
  </div>

  <!-- <div class="p-4 p-md-7 text-center">
    <p class="lead mb-0">¿Dudas con el Seguro de Salud?</p>
    <a href="https://api.whatsapp.com/send?phone=51950831391&text=Hola, te escribo desde la web de Seguros 360, tengo una duda con el Seguro de Salud"
      target="blank">Cuéntanos</a>
  </div> -->

</div>

















<!-- <div class="">

  <div class="d-flex justify-content-center align-items-center" *ngIf="loader">
    <span class="myColorSpinnerSoatDetalle ">
      <mat-spinner class="text-center" [diameter]="36"></mat-spinner>
    </span>
  </div>

  <div class="" *ngIf="!loader">
    <form [formGroup]="formSalud" (ngSubmit)="onSubmit()" novalidate class="row">
      <mat-form-field appearance="outline">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="nombre">
        <mat-error *ngIf="errorNombre()">{{errorNombre()}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email">
        <mat-error *ngIf="errorEmail()">{{errorEmail()}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Celular</mat-label>
        <input matInput formControlName="celular">
        <mat-error *ngIf="errorCelular()">{{errorCelular()}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Edad del titular</mat-label>
        <mat-select formControlName="edadTitular">
          <mat-option *ngFor="let item of edades" [value]="item">
            {{item}} años
          </mat-option>
        </mat-select>
        <mat-error *ngIf="errorEdadTitular()">{{errorEdadTitular()}}</mat-error>
      </mat-form-field>
      <div formArrayName="familiares" class="col-12">
        <div *ngFor="let familiar of formSalud.controls['familiares']['controls']; let i = index;">
          <div class="d-flex" [formGroupName]="i">
            <div class="row w-100 g-3 g-md-4">
              <mat-form-field appearance="outline" class="col-6">
                <mat-label>Vínculo</mat-label>
                <mat-select formControlName="vinculo">
                  <mat-option *ngFor="let vinculo of vinculos" [value]="vinculo">{{vinculo}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-6">
                <mat-label>Edad</mat-label>
                <mat-select formControlName="edad">
                  <mat-option *ngFor="let item of edades" [value]="item">{{item}} años</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex-shrink-1 ms-2" style="padding-top: 18px;">
              <button mat-icon-button (click)="eliminarFamiliar(i)">
                <mat-icon color="primary" class="material-icons-outlined">close</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div>
          <button class="btn btn-light btn-sm text-primary p-0" type="button" (click)="agregarFamiliar()"
            [disabled]="this.formSalud.get('edadTitular').value === ''">
            <mat-icon class="align-middle">add</mat-icon>
            <span class="ml-1">
              Agregar familiar
            </span>
          </button>
        </div>
      </div>
      <div class="d-grid mt-2" *ngIf="!visible">
        <button class="btn btn-secondary myBtn" [disabled]="!formSalud.valid">
          <span class="d-flex justify-content-center" *ngIf="loading">
            <mat-spinner class="text-center" [diameter]="24"></mat-spinner>
          </span>
          <span *ngIf="!loading">VER PRECIOS</span>
        </button>
      </div>
    </form>
  </div>

  <div class="mt-2">
    <p class="superExtraSmall text-muted mb-0 lh-sm">En Rimac a partir de los 61 años afiliación sujeta a examen de
      asegurabilidad. En Mapfre a partir de los 66 años únicamente renovación, en Pacifico a partir de los 65 y en La
      Positiva a partir de los 70.</p>
  </div>

</div>











<div *ngIf="visible">

  <div class="mt-4 mt-md-5" *ngIf="this.formSalud.get('edadTitular').value !== '' ">
    <p class="h4 mb-0">Planes Básicos</p>
    <div class="myAlertSuccess">
      <div class="d-flex mb-1" *ngFor="let beneficio of beneficiosPlanBasico">
        <div class="me-2 d-flex align-items-start">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="20"
            height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#345e30" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="12" cy="12" r="9" />
            <path d="M9 12l2 2l4 -4" />
          </svg>
        </div>
        <p class="small mb-0 lh-1" style="margin-top: 2px;">{{beneficio}}</p>
      </div>
    </div>
  </div>
  <mat-accordion>
    <app-card-resultado-salud *ngFor="let item of primasBasico | async; let primero = first; let ultimo = last"
      [ngClass]="{ 'primerItemAcordion': primero, 'ultimoItemAcordion': ultimo }" [item]="item" [formSalud]="formSalud">
    </app-card-resultado-salud>
  </mat-accordion>



  <div class="mt-4 mt-md-5" *ngIf="this.formSalud.get('edadTitular').value !== ''">
    <p class="h4 mb-0">Planes Avanzados</p>
    <div class="myAlertSuccess">
      <div class="d-flex mb-1" *ngFor="let beneficio of beneficiosPlanAvanzado">
        <div class="me-2 d-flex align-items-start">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="20"
            height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#345e30" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="12" cy="12" r="9" />
            <path d="M9 12l2 2l4 -4" />
          </svg>
        </div>
        <p class="small mb-0 lh-1" style="margin-top: 2px;">{{beneficio}}</p>
      </div>
    </div>
  </div>
  <mat-accordion>
    <app-card-resultado-salud *ngFor="let item of primasAvanzado | async; let primero = first; let ultimo = last"
      [ngClass]="{ 'primerItemAcordion': primero, 'ultimoItemAcordion': ultimo }" [item]="item" [formSalud]="formSalud">
    </app-card-resultado-salud>
  </mat-accordion>

  <div class="mt-4 mt-md-5" *ngIf="this.formSalud.get('edadTitular').value !== ''">
    <p class="h4 mb-0">Planes Premium</p>
    <div class="myAlertSuccess">
      <div class="d-flex mb-1" *ngFor="let beneficio of beneficiosPlanPremium">
        <div class="me-2 d-flex align-items-start">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="20"
            height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#345e30" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="12" cy="12" r="9" />
            <path d="M9 12l2 2l4 -4" />
          </svg>
        </div>
        <p class="small mb-0 lh-1" style="margin-top: 2px;">{{beneficio}}</p>
      </div>
    </div>
  </div>
  <mat-accordion>
    <app-card-resultado-salud *ngFor="let item of primasPremium | async; let primero = first; let ultimo = last"
      [ngClass]="{ 'primerItemAcordion': primero, 'ultimoItemAcordion': ultimo }" [item]="item" [formSalud]="formSalud">
    </app-card-resultado-salud>
  </mat-accordion>

  <div class="mt-4 mt-md-5" *ngIf="this.formSalud.get('edadTitular').value !== ''">
    <p class="h4 mb-0">Planes Internacionales</p>
    <div class="myAlertSuccess">
      <div class="d-flex mb-1" *ngFor="let beneficio of beneficiosPlanInternacional">
        <div class="me-2 d-flex align-items-start">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="20"
            height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#345e30" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="12" cy="12" r="9" />
            <path d="M9 12l2 2l4 -4" />
          </svg>
        </div>
        <p class="small mb-0 lh-1" style="margin-top: 2px;">{{beneficio}}</p>
      </div>
    </div>
  </div>
  <mat-accordion>
    <app-card-resultado-salud *ngFor="let item of primasInternacional | async; let primero = first; let ultimo = last"
      [ngClass]="{ 'primerItemAcordion': primero, 'ultimoItemAcordion': ultimo }" [item]="item" [formSalud]="formSalud">
    </app-card-resultado-salud>
  </mat-accordion>

</div> -->












<!-- <div class="container py-4 py-md-6">
  <p class="h1 text-center mb-4 mb-md-6 text-primary">¿Necesito un seguro de vida?</p>
  <div class="row gx-md-6 gy-6">
    <div class="col-md-4 text-center" *ngFor="let motivo of motivos">
      <img class="rounded-circle imageBeneficioVida mb-3 mb-md-4" [src]="motivo.image" alt="">
      <p class="h5 fw-light px-3 px-md-4">{{motivo.description}}</p>
    </div>
  </div>
</div> -->





<div class="container py-4 py-md-6">
  <p class="h2 text-primary mb-4 text-center">Tipos de Seguros de salud</p>
  <div class="col-md-10 mx-auto">
    <div class="row g-3 g-md-4 justify-content-center">
      <div class="col-md-4" *ngFor="let seguro of tiposSeguros">
        <div
          class="border p-3 px-md-4 border rounded border-secondary border-2 border-primary h-100 d-flex align-items-center">
          <p class="h5 mb-0 fw-bolder lh-sm">{{seguro}}</p>
        </div>
      </div>
    </div>
  </div>
</div>




<div class="container py-3 py-md-4">
  <p class="h2 text-primary mb-4">¿Qué cubre un Seguro de Salud?</p>
  <div class="bg-light p-3 p-md-5 rounded">
    <div class="row gy-2 gx-md-6">
      <div class="col-md-6 d-flex" *ngFor="let beneficio of beneficios">
        <span class="me-2">
          <i-tabler name="check" class="text-success"
            style="width: 24px; stroke-width: 2; stroke-linecap: butt; stroke-linejoin: miter;"></i-tabler>
        </span>
        <p class="lead text-primary mb-0 small">{{beneficio}}</p>
      </div>
    </div>
  </div>
</div>


<div class="container pt-2 pt-md-3 pb-4 pb-md-6">
  <p class="h2 text-primary mb-4">Beneficios Adicionales</p>

  <div class="row g-3 g-md-4 justify-content-center">
    <div class="col-md-4" *ngFor="let adicional of adicionales">
      <div class="bg-light p-3 p-md-5 rounded text-center">
        <p class="h5 mb-0">{{adicional}}</p>
      </div>
    </div>
  </div>


</div>





<div class="container">
  <div class="px-4 pb-5 pb-md-7 text-center">
    <p class="lead">¿Dudas con el Seguro de Salud?</p>
    <a class="btn btn-outline-secondary"
      href="https://api.whatsapp.com/send?phone=51950831391&text=Hola, te escribo desde la web de Seguros 360, tengo una duda con el Seguro de Salud"
      target="blank">
      <span class="me-1">
        <i-tabler name="brand-whatsApp"></i-tabler>
      </span>
      <span>Conversemos</span>
    </a>
  </div>
</div>