<form [formGroup]="formContacto" (ngSubmit)="crearContacto()" novalidate class="row">
  <mat-form-field appearance="outline" class="col-12">
    <mat-label>Nombre</mat-label>
    <input matInput formControlName="nombre">
    <mat-error *ngIf="errorNombre()">{{errorNombre()}}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="col-12">
    <mat-label>Email</mat-label>
    <input matInput formControlName="email">
    <mat-error *ngIf="errorEmail()">{{errorEmail()}}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="col-12 mb-3">
    <mat-label>Celular</mat-label>
    <input matInput formControlName="celular">
    <mat-error *ngIf="errorCelular()">{{errorCelular()}}</mat-error>
  </mat-form-field>
  <div class="col-12 small mb-3 mb-md-4">
    <mat-checkbox formControlName="aceptaTerminosCondiciones">
      Acepto los <button type="button" class="myButtonCheckboxModal text-primary"
        (click)="openModalTerminos()"><u>Términos y condiciones</u></button>
    </mat-checkbox>
    <mat-error *ngIf="errorAceptaTerminosCondiciones()">{{errorAceptaTerminosCondiciones()}}
    </mat-error>
  </div>
  <div class="mt-1 mt-md-2 d-flex justify-content-end">
    <!-- <button class="btn btn-light py-2 px-3 rounded-pill" matStepperPrevious>Atrás</button> -->
    <button type="submit" class="btn btn-primary myBtn" matStepperNext>Siguiente</button>
  </div>
</form>