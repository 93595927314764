import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-cotizador-soat-auto',
  templateUrl: './cotizador-soat-auto.component.html',
  styleUrls: ['./cotizador-soat-auto.component.scss']
})
export class CotizadorSoatAutoComponent implements OnInit {

  formSoatAuto: UntypedFormGroup;
  aseguradoras = [];
  aseguradorasDisponibles = [];
  logosAseguradorasDisponibles = [];
  marcas: any;
  modelosFilter: any;
  itemsSoatAutos: any;

  itemsSoatAutosFilter = [];

  primasSoatAutos = [];
  primasSoatAutosFilter = [];
  visible: boolean;
  disabled = true;
  loading = false;

  points = [
    'Obtén el precio al instante',
    'Compara entre las principales compañías aseguradoras del Perú',
    'Obtén asesoría personalizada sin nungún compromiso'
  ]

  constructor(
    private ds: DataService,
    private fb: UntypedFormBuilder,
    private fs: FirebaseService
  ) { }

  ngOnInit(): void {
    this.formSoatAuto = this.fb.group({
      marca: ['', Validators.required],
      modelo: [{ value: '', disabled: true }, Validators.required],
    });

    // this.aseguradoras = this.ds.aseguradoras;

    // this.aseguradoras = this.ds.aseguradoras.filter( fil =>
    //   fil.nombre === 'Mapfre' ||
    //   fil.nombre === 'Rimac' ||
    //   fil.nombre === 'Qualitas' ||
    //   fil.nombre === 'Pacifico' ||
    //   fil.nombre === 'La Positiva' ||
    //   fil.nombre === 'Crecer'
    //   );

    
    this.aseguradoras = [
      this.ds.aseguradoras[2],
      this.ds.aseguradoras[0],
      this.ds.aseguradoras[4],
      this.ds.aseguradoras[1],
      this.ds.aseguradoras[3],
      this.ds.aseguradoras[5]
    ];

    console.log(this.aseguradoras);

    this.fs.getPrimasSoatAutos().subscribe( res => {
      this.itemsSoatAutos = res;
      const marcasTodas = res.map(a => a.marca);
      this.marcas = [...new Set(marcasTodas)];
    });

    this.formSoatAuto.get('marca').valueChanges.subscribe( res => {
      this.itemsSoatAutosFilter = this.itemsSoatAutos.filter(fil => fil.marca === res);
      this.modelosFilter = this.itemsSoatAutosFilter.map(a => a.modelo);
      this.formSoatAuto.controls.modelo.enable({ emitEvent: false });
      this.formSoatAuto.controls.modelo.setValue('');
      this.visible = false;
    });

    this.formSoatAuto.get('modelo').valueChanges.subscribe( res => {
      this.visible = false;
    });
  }

  filterOrderAseguradoras() {
    this.aseguradoras = [
      this.ds.aseguradoras[2], this.ds.aseguradoras[0]
    ];
  }


  onSubmit() {
    this.loading = true;
    setTimeout(() => {
      this.calcularPrecios();
    }, 300);
  }

  calcularPrecios() {
    this.loading = false;
    const modelo = this.formSoatAuto.get('modelo').value;
    const itemFilter = this.itemsSoatAutosFilter.find(fil => fil.modelo === modelo);
    this.pushPrimas(itemFilter.mapfre, itemFilter.rimac, itemFilter.qualitas, itemFilter.pacifico, itemFilter.laPositiva, itemFilter.crecer );
    this.getPrimasAutosFilter();
    this.visible = true;
  }

  pushPrimas( primaMapfre, primaRimac, primaQualitas, primaPacifico, primaLaPositiva, primaCrecer ) {
    this.primasSoatAutos = [
      {
        aseguradora: this.aseguradoras[0].nombre,
        logo: this.aseguradoras[0].logo,
        plan: 'SOAT Vehicular',
        prima: primaMapfre,
        coberturas: [
          'Muerte hasta cuatro UIT',
          'Invalidez permanente c/u hasta cuatro UIT',
          'Incapacidad temporal c/u hasta una UIT',
          'Gastos médicos c/u hasta cinco UIT',
          'Gastos de sepelio c/u hasta una UIT'
        ]
      },
      {
        aseguradora: this.aseguradoras[1].nombre,
        logo: this.aseguradoras[1].logo,
        plan: 'SOAT Vehicular',
        prima: primaRimac,
        coberturas: [
          'Muerte hasta cuatro UIT',
          'Invalidez permanente c/u hasta cuatro UIT',
          'Incapacidad temporal c/u hasta una UIT',
          'Gastos médicos c/u hasta cinco UIT',
          'Gastos de sepelio c/u hasta una UIT'
        ]
      },
      {
        aseguradora: this.aseguradoras[2].nombre,
        logo: this.aseguradoras[2].logo,
        plan: 'SOAT Vehicular',
        prima: primaQualitas,
        coberturas: [
          'Muerte hasta cuatro UIT',
          'Invalidez permanente c/u hasta cuatro UIT',
          'Incapacidad temporal c/u hasta una UIT',
          'Gastos médicos c/u hasta cinco UIT',
          'Gastos de sepelio c/u hasta una UIT'
        ]
      },
      {
        aseguradora: this.aseguradoras[3].nombre,
        logo: this.aseguradoras[3].logo,
        plan: 'SOAT Vehicular',
        prima: primaPacifico,
        coberturas: [
          'Muerte hasta cuatro UIT',
          'Invalidez permanente c/u hasta cuatro UIT',
          'Incapacidad temporal c/u hasta una UIT',
          'Gastos médicos c/u hasta cinco UIT',
          'Gastos de sepelio c/u hasta una UIT'
        ]
      },
      {
        aseguradora: this.aseguradoras[4].nombre,
        logo: this.aseguradoras[4].logo,
        plan: 'SOAT Vehicular',
        prima: primaLaPositiva,
        coberturas: [
          'Muerte hasta cuatro UIT',
          'Invalidez permanente c/u hasta cuatro UIT',
          'Incapacidad temporal c/u hasta una UIT',
          'Gastos médicos c/u hasta cinco UIT',
          'Gastos de sepelio c/u hasta una UIT'
        ]
      },
      {
        aseguradora: this.aseguradoras[5].nombre,
        logo: this.aseguradoras[5].logo,
        plan: 'SOAT Vehicular',
        prima: primaCrecer,
        coberturas: [
          'Muerte hasta cuatro UIT',
          'Invalidez permanente c/u hasta cuatro UIT',
          'Incapacidad temporal c/u hasta una UIT',
          'Gastos médicos c/u hasta cinco UIT',
          'Gastos de sepelio c/u hasta una UIT'
        ]
      },
    ];
  }

  getPrimasAutosFilter() {
    this.primasSoatAutosFilter = this.primasSoatAutos.sort((a, b) => a.prima < b.prima ? -1 : a.prima > b.prima ? 1 : 0);
  }

  errorMarca() {
    return this.formSoatAuto.controls.marca.hasError('required') ? 'Elige una marca' : '';
  }

  errorModelo() {
    return this.formSoatAuto.get('modelo').hasError('required') ? 'Elige un modelo' : '';
  }

}
