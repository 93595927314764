import { Component, OnInit } from '@angular/core';
import { Noticia } from 'src/app/classes/noticia';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-noticias-block',
  templateUrl: './noticias-block.component.html',
  styleUrls: ['./noticias-block.component.scss']
})
export class NoticiasBlockComponent implements OnInit {

  noticias: Noticia[] = [];

  
  constructor(
    private fs: FirebaseService
  ) { }

  ngOnInit(): void {
    this.fs.getNoticiasHome().subscribe( (data: Noticia[]) => {
      this.noticias = data;
      console.log(this.noticias);
      
    });
  }

}
