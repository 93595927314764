import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-modal-cotizadores',
  templateUrl: './modal-cotizadores.component.html',
  styleUrls: ['./modal-cotizadores.component.scss']
})
export class ModalCotizadoresComponent implements OnInit {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<ModalCotizadoresComponent>,
    private ds: DataService,
  ) { }

  ngOnInit(): void {
  }

  // getPaquetesFijos() {
  //   this.fs.getPaquetesFijos().subscribe( res => {
  //     this.paquetesFijos = res;
  //     this.loaderToursFijos = false;
  //   });
  // }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

}
