<div class="container mb-6 mb-md-9" *ngIf="noticia">

  <nav class="extraSmall my-3 my-md-4">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
      <li class="breadcrumb-item"><a routerLink="/blog">Blog</a></li>
    </ol>
  </nav>

  <div class="row">

    <div class="col-md-7">

      <div class="">
    
        <div class="d-flex align-items-center gap-1 mb-3">
          <div>
            <i-tabler class="text-muted" name="calendar-event"  style="width: 21px; height: 21px; stroke-width: 1.5;"></i-tabler>
          </div>
          <span class="extraSmall text-muted">{{noticia.fecha.toDate() | date:'mediumDate'}}</span>
        </div>


        <p class="h2 fw-bold">{{noticia.titulo}}</p>
        <p class="lead mb-4 mb-md-5 opacity-75">{{noticia.subtitulo}}</p>
        <img class="imageDetalleNoticiaHel mb-3 mb-md-4" [src]="noticia.image" alt="">
        <div class="bodyNoticia" [innerHTML]="noticia.body | safe: 'html' "></div>

      </div>

    </div>

  </div>

</div>