import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formLogin!: FormGroup;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private afAuth: AngularFireAuth,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.formLogin = this.fb.group({
      email: [ '', [Validators.required, Validators.email] ],
      password: [ '', Validators.required ]
    });
  }

  onSubmit() {

    if (this.formLogin.valid) {
      this.emailLogin();
    } else {
      this.validateAllFormFields(this.formLogin);   
    }

  }

  emailLogin() {
    this.loading = true;
    this.afAuth.signInWithEmailAndPassword(this.formLogin.value.email, this.formLogin.value.password)
    .then( () => {
      console.log('Usuario logueado');
      this.router.navigate(['/admin'])
    });
  }

  
  validateAllFormFields(formGroup: FormGroup) {
    this.loading = false;
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
