import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-seguro-detalle',
  templateUrl: './seguro-detalle.component.html',
  styleUrls: ['./seguro-detalle.component.scss']
})
export class SeguroDetalleComponent implements OnInit {

  idSeguro: string;
  seguro;

  constructor(
    private activatedRoute: ActivatedRoute,
    private ds: DataService,
    private tittle: Title,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe( params => {
      this.idSeguro = params.id;
      this.seguro = this.ds.seguros.find( res => res.id === this.idSeguro)
      console.log(this.seguro);
    });
  }

}
