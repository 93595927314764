import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  cotizadores = [
    {
      id: 'salud',
      gorro: 'Cotiza',
      icon: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/icons%2FiconSalud.svg?alt=media&token=15f2c86c-3e3b-4be4-a132-104c99563dd1',
      nombre: 'Seguros de Salud',
      url: '/cotizadores/salud'
    },
    {
      id: 'vehicular',
      gorro: 'Asesórate en',
      icon: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/icons%2FiconAuto.svg?alt=media&token=72e1c24c-0db1-44a8-8183-5ff6ca9758f9',
      nombre: 'Seguro Vehicular',
      url: '/cotizadores/vehicular'
    },
    // {
    //   id: 'soatAuto',
    //   icon: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/icons%2FiconAuto.svg?alt=media&token=72e1c24c-0db1-44a8-8183-5ff6ca9758f9',
    //   nombre: 'SOAT para Autos',
    //   url: '/cotizadores/soatAuto'
    // },
    {
      id: 'vida',
      gorro: 'Asesórate en',
      icon: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/icons%2FiconVida.svg?alt=media&token=7d8855a3-116e-493b-a20d-d6da6bf1dee2',
      nombre: 'Seguros de Vida',
      url: '/cotizadores/vida'
    },
  ];

  aseguradoras = [
    {
      nombre: 'Rimac',
      logo: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/logosAseguradoras%2FlogoRimac.svg?alt=media&token=23380959-3807-4868-8e03-441c6c73da52'
    },
    {
      nombre: 'Pacifico',
      logo: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/logosAseguradoras%2FlogoPacifico.svg?alt=media&token=05ad7ddd-1bfb-4b2b-b171-8fa745f56eae'
    },
    {
      nombre: 'Mapfre',
      logo: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/logosAseguradoras%2FlogoMapfre.svg?alt=media&token=3947dd9c-7b72-4eb7-beae-128dda03094d'
    },
    {
      nombre: 'La Positiva',
      logo: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/logosAseguradoras%2FlogoLaPositiva.svg?alt=media&token=17f55db0-97b9-46bb-91b1-db7f37c9ce32'
    },
    {
      nombre: 'Qualitas',
      logo: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/logosAseguradoras%2FlogoQualitas.svg?alt=media&token=f292183b-f4c6-4386-8f95-a000f18e951a'
    },
    {
      nombre: 'Sanitas',
      logo: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/logosAseguradoras%2FlogoSanitas.svg?alt=media&token=bac6f5fb-706a-4904-ac75-9321875b1e2c'
    },
    {
      nombre: 'Crecer',
      logo: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/logosAseguradoras%2FlogoCrecer.svg?alt=media&token=b8479695-6b85-4996-91b5-5541c28e6407'
    },
    {
      nombre: 'Liberty',
      logo: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/logosAseguradoras%2FlogoLiberty.svg?alt=media&token=03edb036-2294-4c22-9a14-76760a5aa520'
    },
    {
      nombre: 'Ohio',
      logo: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/logosAseguradoras%2FlogoOhio.svg?alt=media&token=a3d89e1e-7e6d-4c5d-bec8-3b90df1cadfa'
    },
  ];

  categorias = [
    {
      titulo: 'Seguros para tu familia',
      seguros: [
        {
          nombre: 'Salud',
          url: '/seguros/seguroSalud'
        },
        {
          nombre: 'Oncológico',
          url: '/seguros/seguroOncologico'
        },
        {
          nombre: 'Universitario',
          url: '/seguros/seguroUniversitario'
        },
        {
          nombre: 'Vida',
          url: '/seguros/seguroVida'
        },
        {
          nombre: 'Jubilación',
          url: '/seguros/seguroJubilacion'
        },
        {
          nombre: 'Viaje',
          url: '/seguros/seguroViaje'
        }
      ],
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/bannerCategorias%2Ffamilia.png?alt=media&token=70a983ef-3961-4ec4-90c5-915efef1c464'
    },
    {
      titulo: 'Seguros para tus bienes y propiedades',
      seguros: [
        {
          nombre: 'Vehicular',
          url: '/seguros/seguroVehicular'
        },
        {
          nombre: 'SOAT',
          url: '/seguros/seguroSoat'
        },
        {
          nombre: 'Hogar y bienes',
          url: '/seguros/seguroHogarBienes'
        },
      ],
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/bannerCategorias%2Fbienes.png?alt=media&token=3bfd57da-4f57-458d-a274-efa7423883dc'
    },
    {
      titulo: 'Seguros para tu empresa',
      seguros: [
        {
          nombre: 'Accidentes personales',
          url: '/seguros/seguroAccidentesPersonales'
        },
        {
          nombre: 'EPS',
          url: '/seguros/seguroEps'
        },
        {
          nombre: 'SCTR',
          url: '/seguros/seguroSctr'
        },
        {
          nombre: 'Vida Ley',
          url: '/seguros/seguroVidaLey'
        },
        {
          nombre: 'Patrimoniales',
          url: '/seguros/seguroPatrimonial'
        },
      ],
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/bannerCategorias%2Fempresa.png?alt=media&token=af8a3b65-7634-4a5e-8388-1cf5fda1f57e'
    },
  ];

  seguros = [
    {
      id: 'seguroSalud',
      categoria: 'familia',
      nombre: 'Seguro de Salud',
      descripcion: 'Contrata el seguro perfecto para cuidar tu salud y la de los tuyos, vive #SinPreocupaciones',
      caracteristicas: [
        'Un seguro con protección para ti y tu familia, con cobertura de consultas médicas, emergencias, hospitalización, maternidad y oncología.',
        'Opción de contratar un seguro médico con reembolso si prefieres elegir a tus propios médicos.'
      ],
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/imagesSeguros%2Ffamilia%2Fsalud.jpg?alt=media&token=c9924ec5-ebc5-428f-ba52-30ecc6412baf',
    },
    {
      id: 'seguroOncologico',
      categoria: 'familia',
      nombre: 'Seguro Oncológico',
      descripcion: 'Contrata el programa oncológico ideal para ti y los tuyos para que estés #SinPreocupaciones',
      caracteristicas: [
        'Coberturas al 100% en tratamientos integrales.',
        'Despistajes anuales y una amplia red de clínicas afiliadas.'
      ],
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/imagesSeguros%2Ffamilia%2Foncologico.jpg?alt=media&token=3558f33e-b03a-488e-b79d-47abe269dd27',
    },
    {
      id: 'seguroUniversitario',
      categoria: 'familia',
      nombre: 'Seguro Universitario',
      descripcion: 'Contrata y ahorra para asegurar el futuro profesional de tus hijos y vive #SinPreocupaciones',
      caracteristicas: [
        'Un fondo acumulado para estudios profesionales de tus hijos.',
        'En caso de fallecimiento, tus hijos reciben la suma asegurada para sus estudios universitarios.'
      ],
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/imagesSeguros%2Ffamilia%2Funiversitario.jpg?alt=media&token=7f68a77d-e68d-4bb7-98b4-1386aa0dd1ae',
    },
    {
      id: 'seguroVida',
      categoria: 'familia',
      nombre: 'Seguro de Vida',
      descripcion: 'Contrata y protege tu familia y patrimonio, nosotros te ayudamos a que vivas con seguridad y #SinPreocupacione',
      caracteristicas: [
        'Protección financiera para tus familiares.',
        'Diversas opciones de planes que te permiten combinar protección y ahorro.'
      ],
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/imagesSeguros%2Ffamilia%2Fvida.jpg?alt=media&token=dcbaca03-5228-49e7-97da-478c7b9b1e98',
    },
    {
      id: 'seguroJubilacion',
      categoria: 'familia',
      nombre: 'Seguro de Jubilación',
      descripcion: 'Contrata un seguro y ahorra para que vivas la mejor etapa de tu vida con bienestar #SinPreocupaciones',
      caracteristicas: [
        'Pensión mensual de por vida.',
        'Pensión de por vida para tus beneficiarios de ley.'
      ],
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/imagesSeguros%2Ffamilia%2Fjubilacion.jpg?alt=media&token=49b94237-ba7c-4382-9c7d-c76b01adb346',
    },
    {
      id: 'seguroViaje',
      categoria: 'familia',
      nombre: 'Seguro de Viaje',
      descripcion: 'Contrata un seguro y haz que tus viajes sean inolvidables y #SinPreocupaciones',
      caracteristicas: [
        'Sin preocupaciones ante imprevistos de viaje.',
        'Asistencia Telefónica y Protección de equipaje.'
      ],
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/imagesSeguros%2Ffamilia%2Fviaje.jpg?alt=media&token=57abb31d-fa92-44e5-9e6d-0aab4c592d12',
    },
    {
      id: 'seguroVehicular',
      categoria: 'bienesPropiedades',
      nombre: 'Seguro Vehicular',
      descripcion: 'Contrata el seguro ideal para ti y tu automóvil, vive con seguridad y #SinPreocupaciones',
      caracteristicas: [
        'Cobertura para choques, robos, daños al vehículo, el de otros y daños a terceros.',
        'Beneficios tales como auxilio mecánico ilimitado, servicio de grúa, conductor y auto de reemplazo.'
      ],
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/imagesSeguros%2FbienesPropiedades%2Fvehicular.jpg?alt=media&token=0dfa2f8c-fce1-4479-9c6a-ff746032deea',
    },
    {
      id: 'seguroSoat',
      categoria: 'bienesPropiedades',
      nombre: 'SOAT',
      descripcion: 'Contrata el SOAT, obtén protección ante accidentes de tráfico, para que estés #SinPreocupaciones',
      caracteristicas: [
        'Atención para tí, tus acompañantes y terceros ante un accidente de tránsito.',
        'Atención médica e indemnización ante incapacidad temporal e invalidez, gastos de sepelio.'
      ],
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/imagesSeguros%2FbienesPropiedades%2Fsoat.jpg?alt=media&token=c1e588c7-6a59-49ac-a0da-2f2043108cb1',
    },
    {
      id: 'seguroHogarBienes',
      categoria: 'bienesPropiedades',
      nombre: 'Hogar y Bienes',
      descripcion: 'Contrata el seguro ideal para tu hogar y vive con bienestar, seguridad y #SinPreocupaciones',
      caracteristicas: [
        'Opción de proteger tu casa, tus pertenencias o ambos.',
        'Indemnización ante eventos imprevistos.'
      ],
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/imagesSeguros%2FbienesPropiedades%2FhogarBienes.jpg?alt=media&token=f79bf05f-5dca-4dde-a808-d6f37b59a403',
    },
    {
      id: 'seguroAccidentesPersonales',
      categoria: 'empresa',
      nombre: 'Accidentes Personales',
      descripcion: 'Contrata un seguro, protégete contra imprevistos, vive tranquilo y #SinPreocupaciones',
      caracteristicas: [
        'Cobertura ante muerte accidental e invalidez total y parcial.',
        'Poder afrontar gastos imprevistos y médicos ante un accidente.'
      ],
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/imagesSeguros%2Fempresa%2FaccidentesPersonales.png?alt=media&token=e0a50e90-1967-4dd3-9c8f-f1bff2a2cb71',
    },
    {
      id: 'seguroEps',
      categoria: 'empresa',
      nombre: 'EPS',
      descripcion: 'Contrata el seguro para tener a tus trabajadores sanos, felices, tranquilos y #SinPreocupaciones',
      caracteristicas: [
        'Amplia red de clínicas en Lima y provincias.',
        'Atención médica a domicilio y una opción amplia de coberturas de acuerdo al plan elegido.'
      ],
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/imagesSeguros%2Fempresa%2Feps.png?alt=media&token=5a5a9f18-a53e-4a77-9b77-b784403cac6e',
    },
    {
      id: 'seguroSctr',
      categoria: 'empresa',
      nombre: 'SCTR',
      descripcion: 'Contrata el seguro obligatorio para tus trabajadores, previene riesgos y desarrolla una cultura de seguridad #SinPreocupaciones',
      caracteristicas: [
        'Seguro obligatorio para cobertura médica y prestaciones económicas.',
        'Programas de prevención de riesgos laborales.'
      ],
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/imagesSeguros%2Fempresa%2Fsctr.png?alt=media&token=0fdc189a-6bea-4435-a4a5-289384941aea',
    },
    {
      id: 'seguroVidaLey',
      categoria: 'empresa',
      nombre: 'Vida Ley',
      descripcion: 'Contrata el seguro obligatorio y protege a tus trabajadores desde el primer día para que estés #SinPreocupaciones',
      caracteristicas: [
        'Coberturas por muerte accidental, natural e invalidez total y permanente por accidente.',
        'Coberturas adicionales según las necesidades de cada empresa'
      ],
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/imagesSeguros%2Fempresa%2FvidaLey.png?alt=media&token=f80d9aa3-416d-4357-b2a4-74d31cf93b4d',
    },
    {
      id: 'seguroPatrimonial',
      categoria: 'empresa',
      nombre: 'Patrimoniales',
      descripcion: 'Contrata el seguro adecuado para proteger los bienes de tu empresa y vive #SinPreocupaciones',
      caracteristicas: [
        'Poder recuperarse ante eventos inesperados.',
        'Coberturas: Incendio, Robo, Deshonestidad, Responsabilidad Civil frente a terceros, Transporte Nacional, Internacional, Rotura de Maquinaria y otros.',
        'Construcción: Protege tus bienes contra daños materiales. Te ofrecemos: CAR, EAR y TREC'
      ],
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/imagesSeguros%2Fempresa%2Fpatrimoniales.png?alt=media&token=ec5f57c8-720b-42fe-8670-af903732adaa',
    },
  ];


  socios = [
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/ricardo.png?alt=media&token=0b65f707-59a3-4473-8c8f-167153a1f88d',
      nombre: 'Ricardo del Águila',
      cargo: 'Socio',
      descripcion: 'Mi formación profesional y los más de 20 años de experiencia en el área comercial del sector seguros y servicios, me han servido para establecer relaciones de largo plazo con clientes y socios de negocios, desarrollando un alto grado de sensibilidad para identificar las necesidades de nuestros clientes en la protección de sus familias y patrimonio.',
      url: 'https://www.linkedin.com/in/ricardo-del-%C3%A1guila-37749041'
    },
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/amilcar.png?alt=media&token=35b58a3e-788d-4f7e-8651-7949803de90d',
      nombre: 'Amilcar Dávila',
      cargo: 'Socio',
      descripcion: 'Soy administrador de Seguros con más de 25 años de experiencia técnica en compañías de seguros, corredores de seguros y como corredor independiente. Durante mi trayectoria, me he enfocado en el análisis de condiciones y coberturas idóneas a las necesidades de mis clientes, así como en el seguimiento y control oportuno de siniestros.',
      url: 'https://www.linkedin.com/in/amilcar-davila-1533711b6'
    },
  ];


  constructor() { }
}
