import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-soat',
  templateUrl: './soat.component.html',
  styleUrls: ['./soat.component.scss']
})
export class SoatComponent implements OnInit {

  opcionesSoat = [
    {
      aseguradoraLogo: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/logosAseguradoras%2FlogoVivir.png?alt=media&token=25326133-7e40-41ee-babb-800f95bbb54c',
      precio: 44,
      urlButton: 'https://www.vivirseguros.pe/soat/?sale=0X2022A11M14D1650',
      caracteristicas: [
        'Cobertura a nivel nacional',
        'Atención de emergencias las 24 horas, los 365 días del año',
        'Chofer de Reemplazo (Copago S/20.00)',
        'Traslado de Grúa (Copago S/35.00)',
        'Servicio de Carwash (S/ 50.00)',
        'Asistencia Mascota (Emergencia Automovilística, hasta S/150)',
        'Asistencia del Hogar (Hasta S/150)',
        'Vida Plus descuentos y tarifas especiales'
      ]
    },
    {
      aseguradoraLogo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoPacifico.svg?alt=media&token=bb52ed5e-dd51-4adf-a775-1aa903e01901',
      precio: 55,
      urlButton: 'https://web.pacifico.com.pe/seguros/soat/compraonline/eagles',
      caracteristicas: [
        'Central 24/7',
        'Hasta 30% de dscto en llantas y aros',
        'S/. 30 en Servicio de auxilio mecanico ligero',
        'Desde S/. 80 en chofer de reemplazo',
        'Desde S/. 90 en servicios de grua',
        'S/. 140 en traslado + revisión técnica'
      ]
    },
    {
      aseguradoraLogo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoRimac.svg?alt=media&token=c51c72ea-e0b5-4ba3-93c6-7b7e69b8b969',
      precio: 85,
      urlButton: 'https://www.rimac.com/comprar/soat-digital?partner=eagles',
      caracteristicas: [
        'Acceso directo desde App Mundo Rimac',
        '1 año de dscto en Repsol',
        '20% dsct en Gruas y asistencia viales',
        'Más de 20% de dscto en baterias',
      ]
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
