import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-seguros',
  templateUrl: './seguros.component.html',
  styleUrls: ['./seguros.component.scss']
})
export class SegurosComponent implements OnInit {

  categorias = [];

  constructor(
    private ds: DataService
  ) { }

  ngOnInit(): void {
    this.categorias = this.ds.categorias;
  }

}
