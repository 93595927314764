<div class="container py-3 py-md-6">

  <div class="row align-items-center gx-md-6 mb-4 gy-4">

    <!-- <div class="col-md-12">
      <img class="w-100"
        src="https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/imgSoatEagles.png?alt=media&token=8ba569ad-50bf-4ee1-866a-180f8a27cc8a"
        alt="">
    </div> -->

    <div class="col-md-12">
      <div class="container">
        <div class="row align-items-center gx-md-6 gy-4">
          <div class="col-md-6 text-center text-md-start">
            <p class="display-4 fw-bold">Compra tu SOAT desde S/. 44</p>  
          </div>
          <div class="col-md-6">
            <img class="w-100"
              src="https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/imgSoatEagles.png?alt=media&token=8ba569ad-50bf-4ee1-866a-180f8a27cc8a"
              alt="">
          </div>
        </div>
      </div>
    </div>


    <div class="col-md-12">
      <!-- <p class="lead">Compra tu <span class="fw-bold">SOAT DIGITAL</span> al instante</p> -->
      <div class="row g-3">
        <div class="col-12 col-md-4" *ngFor="let item of opcionesSoat">

          <div class="position-relative itemSoat bg-white rounded border border-2 p-3 p-md-4 h-100">

            <div class="d-flex flex-column h-100">

              <div>

                <div class="d-flex justify-content-between align-items-center mb-2">
                  <div>
                    <img class="aseguradoraSoat" [src]="item.aseguradoraLogo">
                  </div>
                  <div>
                    <span class="extraSmall text-muted">DESDE</span>
                    <p class="fw-bold">S/. {{item.precio}}</p>
                  </div>
                </div>

                <div class="row gy-2">
                  <div class="col-12" *ngFor="let caracteristica of item.caracteristicas">
                    <div class="d-flex">
                      <span>
                        <i-tabler name="check" class="text-success me-2"></i-tabler>
                      </span>
                      <p class="mb-0 extraSmall lh-sm">{{caracteristica}}</p>
                    </div>
                  </div>
                </div>

              </div>

              <div class="mt-auto">
                <div class="d-grid mt-3 mt-md-4">
                  <a class="btn btn-success btn-sm stretched-link" [href]="item.urlButton" target="_blank">COMPRAR</a>
                </div>
              </div>

              

            </div>

            

          </div>

        </div>
      </div>
    </div>

  </div>

</div>