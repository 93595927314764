import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-me-interesa',
  templateUrl: './me-interesa.component.html',
  styleUrls: ['./me-interesa.component.scss']
})
export class MeInteresaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
