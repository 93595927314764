<div class="container d-flex justify-content-center align-items-center h-100">

  <div class="text-center">

    <img class="logoSeguros360 mb-4 mb-md-5" src="https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/logoSeguros360.svg?alt=media&token=88975f30-8b60-421c-ae91-1cf125f4be5f" alt="logo Sensorial Models">

    <form [formGroup]="formLogin" (submit)="onSubmit()" novalidate class="row">

      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput type="text" formControlName="email">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="password">
      </mat-form-field>

      <div class="d-grid">
        <button type="submit" class="btn btn-primary">
          <span class="spinnerDark d-flex justify-content-center" *ngIf="loading">
            <mat-spinner class="text-center" [diameter]="24" class="float-left mr-2"></mat-spinner>
          </span>
          <span *ngIf="!loading">INGRESAR</span>
        </button>
      </div>

    </form>

  </div>

</div>