import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

export class ContactoVida {
  nombre: string;
  email: string;
  celular: number;
  horaContacto: string;

  constructor(nombre: string, email: string, celular: number, horaContacto: string) {
    this.nombre = nombre;
    this.email = email;
    this.celular = celular;
    this.horaContacto = horaContacto;
  }
}

@Component({
  selector: 'app-gracias-seguro-vida',
  templateUrl: './gracias-seguro-vida.component.html',
  styleUrls: ['./gracias-seguro-vida.component.scss']
})
export class GraciasSeguroVidaComponent implements OnInit {

  contacto: ContactoVida;

  constructor(
    private activatedRoute: ActivatedRoute,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe( res => {
      console.log(res.id);
      this.afs.collection('cotizacionesVida').doc(res.id).valueChanges().subscribe( (data: ContactoVida) => {
        this.contacto = data;
        console.log(data);
      });
    });
  }

}
