import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-cotizador-salud',
  templateUrl: './cotizador-salud.component.html',
  styleUrls: ['./cotizador-salud.component.scss']
})
export class CotizadorSaludComponent implements OnInit {

  formContacto: UntypedFormGroup;
  formCotizador: UntypedFormGroup;
  linear = false;
  cotizacionId: string;
  valoresSalud = [];

  constructor(
    private afs: AngularFirestore
  ) {
    this.cotizacionId = this.afs.collection('cotizacionesSalud').ref.doc().id;
    console.log(this.cotizacionId);
  }

  ngOnInit(): void {
  }

  recibirValoresSalud( valores ) {
    this.valoresSalud = valores;
  }


}
