<div class="container mb-4 mb-md-6">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb extraSmall mb-3 mb-md-4">
      <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
      <li class="breadcrumb-item active">Seguros</li>
    </ol>
  </nav>
  <div class="row g-3 g-md-5">
    <div class="col-md-4" *ngFor="let item of categorias">
      <div class="categoriaHome h-100">
        <div class="p-4 p-md-5">
          <p class="h3 mb-3">{{item.titulo}}</p>
          <div class="d-flex py-1" *ngFor="let seguro of item.seguros">
            <div class="me-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right" width="24"
                height="24" viewBox="0 0 24 24" stroke-width="1" stroke="#1446A3" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1="5" y1="12" x2="19" y2="12" />
                <line x1="15" y1="16" x2="19" y2="12" />
                <line x1="15" y1="8" x2="19" y2="12" />
              </svg>
            </div>
            <div><a class="text-secondary" [routerLink]="seguro.url">{{seguro.nombre}}</a></div>
          </div>
        </div>
        <div class="">
          <img class="imgCategoriaSeguros" [src]="item.image">
        </div>
      </div>

    </div>
  </div>
</div>