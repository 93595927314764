import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Timestamp } from '@angular/fire/firestore';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-cotizador-vida',
  templateUrl: './cotizador-vida.component.html',
  styleUrls: ['./cotizador-vida.component.scss']
})
export class CotizadorVidaComponent implements OnInit {

  formVida: UntypedFormGroup;
  loading: boolean;
  points = [
    'Adicionalmente a la cobertura de vida, podrás contratar coberturas adicionales para ti y tu familia en cualquier momento',
    'Podrás elegir pagar la prima de tu póliza en dólares o soles',
    'Puedes escoger el portafolio de inversión que más te convenga',
    'Puedes realizar retiros parciales y préstamos del seguro',
    'Es endosable a favor de entidades financieras, en reemplazo de tu seguro de desgravamen',
    'Puedes modificar tu suma asegurada cuando quieras',
    'Te ofrecemos un descuento sobretu prima, si realizas el pago anual al momento de la compra'
  ];

  rangosHora = [
    'Por la mañana (9:00 am - 1:00 pm)',
    'Por la tarde (2:00 pm - 5:00 pm)',
    'Por la noche (7:00 pm - 9:00 pm)'
  ];

  contactoId: string;

  motivos = [
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/seguroVida%2F1Baja.jpg?alt=media&token=f9c9e1b5-757e-4ce3-a8ee-339a2ada1809',
      description: 'Si tienes hijos menores de 18 años, planifica su futuro y estudios escolares y universitarios.'
    },
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/seguroVida%2F2Baja.jpg?alt=media&token=d7cf48e0-38be-4ee8-a4f9-37f953e83030',
      description: 'Si tienes un crédito hipotecario, puedes reemplazar el seguro de desgravamen.'
    },
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/eagles-e1e02.appspot.com/o/seguroVida%2F3Baja.jpg?alt=media&token=786efa13-fded-42a6-927c-44ad6afd6551',
      description: 'Si eres joven y aún no tienes hijos, ahorra para cumplir tus metas de vida: un auto nuevo, tu depa.'
    },
  ];

  tiposSeguros = [
    'Seguro de vida con devolución',
    'Seguro de vida con Inversión',
    'Seguro de vida con renta garantizada',
    'Seguro de Vida temporal',
    'Fondo universitario',
    'Seguros de vida con fondo garantizado',
    'Seguro de vida con ahorro a largo plazo',
    'Seguro de vida solo con proteccion'
  ];

  beneficios = [
    'Fallecimiento (natural o accidental)',
    'Indemnización adicional por fallecimiento accidental - Sepelio',
    'Exoneración de pago de primas',
    'Anticipo de capital por enfermedad grave (50% el capital asegurado)',
    'Pago anticipado de capital por Invalidez total y permanente por enfermedad o accidente',
    'Invalidez total y permanente por enfermedad grave o accidente',
    'Incapacidad total  (no poder valerse por uno mismo).',
    'Renta hospitalaria por accidente',
    'Indemnización por cirugía',
    'Sepelio para padres e hijos'
  ];

  adicionales = [
    'Orientación Medica Telefónica',
    'Medico a Domicilio',
    'Telemedicina',
    'Ambulancia',
    'Chequeo Preventivo',
    'Asistencia Odontológica'
  ]

  constructor(
    private fb: UntypedFormBuilder,
    private afs: AngularFirestore,
    private router: Router
  ) {
    this.contactoId = this.afs.createId();
    console.log(this.contactoId);
    
  }

  ngOnInit(): void {
    this.formVida = this.fb.group({
      nombre: ['', Validators.required],
      celular: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(9), Validators.maxLength(9)]],
      email: ['', [Validators.email, Validators.required]],
      horaContacto: ['Por la mañana (9:00 am - 1:00 pm)', Validators.required],
      fechaCreacion: [Timestamp.now()]
    });
  }

  onSubmit() {
    if (this.formVida.valid) {
      this.guardarContactoVida();
      this.loading = true
    } else {
      this.validateAllFormFields(this.formVida);
      this.loading = false;
    }
  }

  guardarContactoVida() {
    this.afs.collection('cotizacionesVida').doc(this.contactoId).set(this.formVida.value)
    .then( () => {
      this.router.navigate([`/cotizadores/vida/${this.contactoId}`])
    });
  }


  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }



  errorNombre() {
    return this.formVida.controls.nombre.hasError('required') ? 'Ingrese un nombre' :
        '';
  }

  errorEmail() {
    return this.formVida.controls.email.errors?.required ? 'Ingrese un email' :
      this.formVida.controls.email.errors?.email ? 'Ingrese un email válido' :
        '';
  }

  errorCelular() {
    return this.formVida.controls.celular.hasError('required') ? 'Ingrese el número de celular' :
    this.formVida.controls.celular.hasError('pattern') ? 'Solo se admiten números' :
    this.formVida.controls.celular.hasError('minlength') ? 'Debe contener 9 dígitos' :
    this.formVida.controls.celular.hasError('maxlength') ? 'No debe exceder los 9 dígitos' :
    '';
  }

  errorHoraContacto() {
    return this.formVida.controls.horaContacto.hasError('required') ? 'Ingrese una opción' : '';
  }


}
