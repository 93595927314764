import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin/admin.component';
import { CotizadorSaludComponent } from './components/public/cotizadores/cotizador-salud/cotizador-salud.component';
import { CotizadoresComponent } from './components/public/cotizadores/cotizadores.component';
import { InicioComponent } from './components/public/inicio/inicio.component';
import { LoginComponent } from './components/public/login/login.component';
import { PublicComponent } from './components/public/public/public.component';


import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { InicioAdminComponent } from './admin/inicio-admin/inicio-admin.component';
import { SeguroDetalleComponent } from './components/public/seguros/seguro-detalle/seguro-detalle.component';
import { SegurosComponent } from './components/public/seguros/seguros.component';
import { NosotrosComponent } from './components/public/nosotros/nosotros.component';
import { ContactoComponent } from './components/public/contacto/contacto.component';
import { CotizadorSoatAutoComponent } from './components/public/cotizadores/cotizador-soat-auto/cotizador-soat-auto.component';
import { FormCotizadorSaludComponent } from './components/public/cotizadores/cotizador-salud/form-cotizador-salud/form-cotizador-salud.component';
import { LibroReclamacionesComponent } from './components/public/libro-reclamaciones/libro-reclamaciones.component';
import { GraciasReclamoComponent } from './components/public/gracias-reclamo/gracias-reclamo.component';
import { TerminosCondicionesComponent } from './components/public/terminos-condiciones/terminos-condiciones.component';
import { PoliticaPrivacidadComponent } from './components/public/politica-privacidad/politica-privacidad.component';
import { VehicularComponent } from './components/public/cotizadores/vehicular/vehicular.component';
import { CotizadorVidaComponent } from './components/public/cotizadores/cotizador-vida/cotizador-vida.component';
import { GraciasSeguroVidaComponent } from './components/public/cotizadores/gracias-seguro-vida/gracias-seguro-vida.component';
import { GraciasVehicularComponent } from './components/public/cotizadores/vehicular/gracias-vehicular/gracias-vehicular.component';
import { SoatComponent } from './components/public/soat/soat.component';
import { DetalleNoticiaComponent } from './components/public/detalle-noticia/detalle-noticia.component';
import { BlogComponent } from './components/public/blog/blog.component';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/login']);

const routes: Routes = [
  {
    path: '', component: PublicComponent,
    children: [
      { path: '', component: InicioComponent },
      { path: 'cotizadores', component: CotizadoresComponent },
      { path: 'cotizadores/salud', component: FormCotizadorSaludComponent },
      { path: 'cotizadores/soatAuto', component: CotizadorSoatAutoComponent },
      { path: 'cotizadores/vehicular', component: VehicularComponent },
      { path: 'cotizadores/vehicular/gracias/:id', component: GraciasVehicularComponent },
      { path: 'cotizadores/vida', component: CotizadorVidaComponent },
      { path: 'cotizadores/vida/:id', component: GraciasSeguroVidaComponent },
      { path: 'seguros', component: SegurosComponent },
      { path: 'seguros/:id', component: SeguroDetalleComponent },
      { path: 'nosotros', component: NosotrosComponent },
      { path: 'contacto', component: ContactoComponent },
      { path: 'libroReclamaciones', component: LibroReclamacionesComponent },
      { path: 'graciasReclamo', component: GraciasReclamoComponent },
      { path: 'terminosCondiciones', component: TerminosCondicionesComponent },
      { path: 'politicaPrivacidad', component: PoliticaPrivacidadComponent },
      { path: 'soat', component: SoatComponent },
      { path: 'blog', component: BlogComponent },
      { path: 'blog/:id', component: DetalleNoticiaComponent },

    ]
  },
  { path: 'login', component: LoginComponent },
  // {
  //   path: 'admin', component: AdminComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
  //   children: [
  //     { path: '', component: InicioAdminComponent },
  //   ]
  // },
  // { path: 'admin', loadChildren: () => import('./admin/admin.module').then( m => m.AdminModule), canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then( m => m.AdminModule), canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: '', redirectTo: 'es', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
