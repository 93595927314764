<div *ngIf="valores.visible">

  <p>Hola mi valor es:</p>

  <div class="mt-4 mt-md-5" *ngIf="valores.formSalud.get('edadTitular').value !== '' ">
    <p class="h4 mb-0">Planes Básicos</p>
    <div class="myAlertSuccess">
      <div class="d-flex mb-1" *ngFor="let beneficio of valores.beneficiosPlanBasico">
        <div class="me-2 d-flex align-items-start">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="20"
            height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#345e30" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="12" cy="12" r="9" />
            <path d="M9 12l2 2l4 -4" />
          </svg>
        </div>
        <p class="small mb-0 lh-1" style="margin-top: 2px;">{{beneficio}}</p>
      </div>
    </div>
  </div>
  <mat-accordion>
    <app-card-resultado-salud *ngFor="let item of valores.primasBasico | async; let primero = first; let ultimo = last"
      [ngClass]="{ 'primerItemAcordion': primero, 'ultimoItemAcordion': ultimo }" [item]="item" [formSalud]="valores.formSalud">
    </app-card-resultado-salud>
  </mat-accordion>

  <div class="mt-4 mt-md-5" *ngIf="valores.formSalud.get('edadTitular').value !== ''">
    <p class="h4 mb-0">Planes Avanzados</p>
    <div class="myAlertSuccess">
      <div class="d-flex mb-1" *ngFor="let beneficio of valores.beneficiosPlanAvanzado">
        <div class="me-2 d-flex align-items-start">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="20"
            height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#345e30" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="12" cy="12" r="9" />
            <path d="M9 12l2 2l4 -4" />
          </svg>
        </div>
        <p class="small mb-0 lh-1" style="margin-top: 2px;">{{beneficio}}</p>
      </div>
    </div>
  </div>
  <mat-accordion>
    <app-card-resultado-salud *ngFor="let item of valores.primasAvanzado | async; let primero = first; let ultimo = last"
      [ngClass]="{ 'primerItemAcordion': primero, 'ultimoItemAcordion': ultimo }" [item]="item" [formSalud]="valores.formSalud">
    </app-card-resultado-salud>
  </mat-accordion>

  <div class="mt-4 mt-md-5" *ngIf="valores.formSalud.get('edadTitular').value !== ''">
    <p class="h4 mb-0">Planes Premium</p>
    <div class="myAlertSuccess">
      <div class="d-flex mb-1" *ngFor="let beneficio of valores.beneficiosPlanPremium">
        <div class="me-2 d-flex align-items-start">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="20"
            height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#345e30" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="12" cy="12" r="9" />
            <path d="M9 12l2 2l4 -4" />
          </svg>
        </div>
        <p class="small mb-0 lh-1" style="margin-top: 2px;">{{beneficio}}</p>
      </div>
    </div>
  </div>
  <mat-accordion>
    <app-card-resultado-salud *ngFor="let item of valores.primasPremium | async; let primero = first; let ultimo = last"
      [ngClass]="{ 'primerItemAcordion': primero, 'ultimoItemAcordion': ultimo }" [item]="item" [formSalud]="valores.formSalud">
    </app-card-resultado-salud>
  </mat-accordion>

  <div class="mt-4 mt-md-5" *ngIf="valores.formSalud.get('edadTitular').value !== ''">
    <p class="h4 mb-0">Planes Internacionales</p>
    <div class="myAlertSuccess">
      <div class="d-flex mb-1" *ngFor="let beneficio of valores.beneficiosPlanInternacional">
        <div class="me-2 d-flex align-items-start">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="20"
            height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#345e30" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="12" cy="12" r="9" />
            <path d="M9 12l2 2l4 -4" />
          </svg>
        </div>
        <p class="small mb-0 lh-1" style="margin-top: 2px;">{{beneficio}}</p>
      </div>
    </div>
  </div>
  <mat-accordion>
    <app-card-resultado-salud *ngFor="let item of valores.primasInternacional | async; let primero = first; let ultimo = last"
      [ngClass]="{ 'primerItemAcordion': primero, 'ultimoItemAcordion': ultimo }" [item]="item" [formSalud]="valores.formSalud">
    </app-card-resultado-salud>
  </mat-accordion>

</div>

