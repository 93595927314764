<div class="container pt-3 pt-md-4 pb-6 pb-md-10">

  <div class="row">

    <!-- <div class="col-md-4">
      <p class="h2">Nuestro Blog</p>
      <p class="text-muted">Sigue nuestro contenido y mantente informado</p>
      <a class="btn btn-outline-primary" routerLink="/noticias">Ver todas las noticias</a>
    </div> -->
  
    <div class="col-md-12">

      <div class="mb-4 mb-md-5">
        <p class="h2">Nuestro Blog</p>
        <p class="text-muted">Sigue nuestro contenido y mantente informado</p>
      </div>

      

      <div class="row g-md-4 gy-3">
        <div class="col-md-6" *ngFor="let noticia of noticias">
          <div class="shadow-sm rounded h-100 bg-white position-relative">
            <img class="imageNoticiaCard rounded-top" [src]="noticia.image" alt="">
            <div class="p-3 p-md-4 rounded-bottom pt-2 pt-md-3">
              <div class="rounded-pill px-2 py-1 bg-warning d-inline-flex mb-2 extraSmall">{{noticia.fecha.toDate() | date: 'mediumDate' }}</div>
              <p class="h5 fw-light">{{noticia.titulo}}</p>
              <!-- <p class="small text-muted mb-0">{{noticia.subtitulo}}</p> -->
            </div>
            <a class="stretched-link" [routerLink]=" '/blog/' + noticia.id "></a>
          </div>
        </div>
      </div>
    </div>
  
  </div>

</div>
