import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Timestamp } from '@angular/fire/firestore';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as firebase from 'firebase/app';
import { ModalTerminosComponent } from '../../modal-terminos/modal-terminos.component';

@Component({
  selector: 'app-form-contacto',
  templateUrl: './form-contacto.component.html',
  styleUrls: ['./form-contacto.component.scss']
})
export class FormContactoComponent implements OnInit {

  @Input() cotizacionId!: string;

  formContacto!: UntypedFormGroup;
  loader!: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private afs: AngularFirestore,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.formContacto = this.fb.group({
      nombre: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      celular: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(9), Validators.maxLength(9)]],
      aceptaTerminosCondiciones: [false, Validators.requiredTrue],
      fechaCreacion: [Timestamp.now()]
    });
  }


  // onSubmit() {
  //   if (this.formContacto.valid) {
  //     this.guardarContacto();
  //     this.loader = true;
  //   } else {
  //     this.validateAllFormFields(this.formContacto);
  //     this.loader = false;
  //   }
  // }

  crearContacto() {
    if (this.formContacto.valid) {
      this.guardarContacto();
      this.loader = true;
    } else {
      this.validateAllFormFields(this.formContacto);
    }
  }


  guardarContacto() {
    this.afs.doc('cotizacionesSalud/' + this.cotizacionId).set({
      contacto: this.formContacto.value
    }, { merge: true });
  }


  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  openModalTerminos() {
    this.matDialog.open(ModalTerminosComponent, {
      panelClass: 'dialogTerminosCondiciones',
    });
  }


  errorEmail() {
    return this.formContacto.controls.email.errors?.required ? 'Ingrese un email' :
      this.formContacto.controls.email.errors?.email ? 'Ingrese un email válido' :
        '';
  }

  errorNombre() {
    return this.formContacto.controls.nombre.errors?.required ? 'Ingrese su nombre' : '';
  }


  errorCelular() {
    return this.formContacto.controls.celular.hasError('required') ? 'Ingrese el celular' :
      this.formContacto.controls.celular.hasError('pattern') ? 'Solo se admiten números' :
        this.formContacto.controls.celular.errors?.minlength ? 'Debe contener 9 dígitos' :
          this.formContacto.controls.celular.errors?.maxlength ? 'No debe exceder lo 9 dígitos' : '';
  }

  errorAceptaTerminosCondiciones() {
    return this.formContacto.controls.aceptaTerminosCondiciones.hasError('required') &&
      this.formContacto.controls.aceptaTerminosCondiciones.touched ? 'Es necesario aceptar los términos' : '';
  }

}
