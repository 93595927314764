<mat-expansion-panel>
  <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
    <mat-panel-description class="w-100 me-3">
      <div class="d-flex flex-column w-100">
        <div class="d-flex justify-content-between w-100">
          <div class="me-2 me-md-4">
            <img class="logoResultadoSalud" [src]="item.logo">
          </div>
          <div class="d-flex justify-content-between align-items-center w-100">
            <div>
              <p class="planSalud mb-0">{{item.plan}}</p>
              <p class="small mb-0">{{formSoatAuto.get('marca').value}} - {{formSoatAuto.get('modelo').value}}</p>
            </div>
            <div class="text-end ms-3 ms-md-5">
              <p class="primaSalud mb-0">S/ {{item.prima | number: '1.0-2'}}</p>
              <p class="small text-muted mb-0" style="white-space: nowrap;">por año</p>
            </div>
          </div>
        </div>

      </div>

    </mat-panel-description>
  </mat-expansion-panel-header>
  <div>
    <mat-divider class="mb-2"></mat-divider>

    <div class="d-flex listaCaracteristicas" *ngFor="let cobertura of item.coberturas | slice:0:5 ; first as first">
      <div class="me-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="16" height="16" viewBox="0 0 24 24" stroke-width="2.5" stroke="#82EA79" fill="none" stroke-linecap="round" stroke-linejoin="round" style="vertical-align: top;">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M5 12l5 5l10 -10" />
        </svg>
      </div>
      <div>
        <p class="mb-0" [ngClass]="{'semibold': first}">{{cobertura}}</p>
      </div>
    </div>

<!-- 
    <div class="mt-3 mb-2">
      <div>
        <div class="d-flex justify-content-between">
          <span>Titular <span class="text-muted">({{formSalud.get('edadTitular').value}} años)</span></span>
          <span>S/ {{item.costoTitular | number: '1.0-0'}} <span class="text-muted">al mes</span></span>
        </div>
        <mat-divider class="my-1"></mat-divider>
      </div>
      <div *ngFor="let familiar of item.familiares">
        <div class="d-flex justify-content-between">
          <span>{{familiar.vinculo}} <span class="text-muted">({{familiar.edad}} años)</span></span>
          <span>S/ {{familiar.costo | number: '1.0-0'}} <span class="text-muted">al mes</span></span>
        </div>
        <mat-divider class="my-1"></mat-divider>
      </div>
    </div> -->

    <!-- <div class="row g-1">
      <div class="col-12 d-grid">
        <button class="btn btn-light btn-sm text-primary" (click)="openModalClinicas()">Clínicas y copagos</button>
      </div> 
      <div class="col-12 d-grid">
        <button class="btn btn-light btn-sm text-primary" (click)="openModalCoberturas()">Coberturas y exclusiones</button>
      </div>

    </div> -->

    <div class="mt-2 d-grid">
      <!-- <button class="btn btn-primary shadow-sm myBtn" (click)="openModalMeInteresa()">Me interesa</button> -->
      <a class="btn btn-primary shadow-sm myBtn" [href]="mensajeWhatsApp" target="_blank">ME INTERESA
        <span class="ms-1">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-whatsapp" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
            <path d="M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1" />
          </svg>
        </span>
    
      </a>
    </div>

    
  </div>
</mat-expansion-panel>