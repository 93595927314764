import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MatDialog } from '@angular/material/dialog';
// import { FormContactDirectComponent } from '../../form-contact-direct/form-contact-direct.component';
// import { ModalMeInteresaComponent } from '../../modal-me-interesa/modal-me-interesa.component';

@Component({
  selector: 'app-card-soat-auto',
  templateUrl: './card-soat-auto.component.html',
  styleUrls: ['./card-soat-auto.component.scss']
})
export class CardSoatAutoComponent implements OnInit {

  @Input() item;
  @Input() formSoatAuto;

  mensajeWhatsApp: string;

  constructor(
    private analytics: AngularFireAnalytics,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.mensajeWhatsApp = `https://api.whatsapp.com/send?phone=51950831391&text=Hola, te escribo desde la web de Seguros 360. Me interesa un SOAT para mi auto/camioneta marca ${this.formSoatAuto.get('marca').value}, modelo ${this.formSoatAuto.get('modelo').value}, con ${this.item.aseguradora} a S/. ${this.item.prima}`;
  }

  // openModalContactDirect() {
  //   this.analytics.logEvent('botonSoatMoto');
  //   this.dialog.open(FormContactDirectComponent, {
  //     panelClass: 'dialogDeleteSolicitud',
  //     data: {
  //       item_: this.item,
  //       formSoatMoto_: this.formSoatAuto
  //     }
  //   });
  // }

  // openModalMeInteresa() {
  //   this.dialog.open(ModalMeInteresaComponent, {
  //     panelClass: 'dialogDeleteSolicitud',
  //     data: {
  //       item_: this.item,
  //       formSoatAuto_: this.formSoatAuto
  //     }
  //   });
  // }

}
