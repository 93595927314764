import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-coberturas-salud',
  templateUrl: './coberturas-salud.component.html',
  styleUrls: ['./coberturas-salud.component.scss']
})
export class CoberturasSaludComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CoberturasSaludComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  // openModalContactoSeguroSalud() {
  //   this.dialog.open(FormContactoSaludComponent, {
  //     panelClass: 'modalContactoSeguroSalud',
  //     data: this.data
  //   });
  // }

  cancelar(): void {
    this.dialogRef.close();
  }

  goToConsolidado(consolidado) {
    // window.location.href = `${cartilla}`;
    window.open(`${consolidado}`, '_blank');
  }

}
